// import React, {useContext} from 'react';
import { AuthContext } from "../utils/Context";
import React, { useContext, useState, useEffect } from "react";
import Header from "../components/Header/Header";
import { ConfigInput } from "../components/ConfigInput/ConfigInput";
import Select from "react-select";
import { getCookie } from "../utils/Cookies";

function Configuration() {
  const { projectData, setProjectData } = useContext(AuthContext);
  const [currentPlotPos, setCurrentPlotPos] = useState(0);
  const [currentPlot, setCurrentPlot] = useState(
    { ...projectData }.entities[currentPlotPos]
  );
  const [confirmation, showConfirmation] = useState(false);

  useEffect(() => {
    if (!projectData) {
      const localProject = JSON.parse(localStorage.getItem("project"));
      setProjectData(localProject);
      setCurrentPlot({ ...localProject }.entities[0]);
    }
  }, [projectData, currentPlot]);

  if (!projectData) {
    return <></>;
  }

  let dataCookie = getCookie("name");

  let projectLevels = Object.entries(projectData.config.levels);
  let plotOptions = [];
  for (let entity in projectData.entities) {
    plotOptions.push({
      label: projectData.entities[entity].name,
      value: entity,
    });
  }

  const selectPlot = (e) => {
    let thisPlot = { ...projectData }.entities[e.value];
    setCurrentPlot(thisPlot);
    setCurrentPlotPos(e.value);
    document.getElementById("projectData-form").reset();
  };

  const KPIInputs = (inputData, originalIndex) => {
    return (
      <>
        {inputData.map((data, index) => (
          <ConfigInput
            title={data.name}
            code={"entity.kpi." + originalIndex + ".targets." + index}
            value={data.value}
            width={250}
          />
        ))}
      </>
    );
  };

  const updateProject = () => {
    showConfirmation(true);
    let projectLevels = { ...projectData }.config.levels;
    for (let level in projectLevels) {
      projectLevels[level].name = document.getElementById(
        "config.levels." + level + ".name"
      ).value;
    }

    let plotKPIs = { ...currentPlot }.kpi;
    for (let target in plotKPIs) {
      for (let level in plotKPIs[target].targets) {
        plotKPIs[target].targets[level].value = Number(
          document.getElementById("entity.kpi." + target + ".targets." + level)
            .value
        );
      }
    }

    let updatedPlot = {
      ...currentPlot,
      name: document.getElementById("entity.name").value,
      setup: {
        existingTreeCanopy: Number(
          document.getElementById("entity.setup.existingTreeCanopy").value
        ),
        existingBiodiversityUnits: Number(
          document.getElementById("entity.setup.existingBiodiversityUnits")
            .value
        ),
      },
      kpi: plotKPIs,
    };

    let updatedProjectData = {
      ...projectData,
      projectName: document.getElementById("projectName").value,
      projectDescription: document.getElementById("projectDescription").value,
      client: document.getElementById("client").value,
      config: {
        method: document.getElementById("config.method").value,
        trees: {
          smallTreeCanopySize: Number(
            document.getElementById("config.trees.smallTreeCanopySize").value
          ),
          mediumTreeCanopySize: Number(
            document.getElementById("config.trees.mediumTreeCanopySize").value
          ),
          largeTreeCanopySize: Number(
            document.getElementById("config.trees.largeTreeCanopySize").value
          ),
        },
        levels: projectLevels,
      },
    };
    document.getElementById("projectData-form").reset();
    updatedProjectData.entities[currentPlotPos] = updatedPlot;

    setProjectData(updatedProjectData);
    setCurrentPlot(updatedPlot);
    localStorage.setItem("project", JSON.stringify(updatedProjectData));
    fetch("/save-data/" + dataCookie, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedProjectData),
    }).then((response) => {
      console.log(response);
    });
    setTimeout(() => {
      showConfirmation(false);
    }, 1500);
  };

  const backUp = () => {
    fetch("/mass-backup/" + dataCookie, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const file = new Blob([blob], { type: "application/json" });
        const fileUrl = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = dataCookie + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const SaveButton = () => {
    let thisText = "Save Changes";
    let thisClass = "normal";

    if (confirmation) {
      thisText = "Saved!";
      thisClass = "activated";
    }
    return (
      <button onClick={updateProject} className={thisClass} type="button">
        {thisText}
      </button>
    );
  };

  return (
    <>
      <Header />
      <div className="background">
        <div className="wrapper">
          <div className="configuration">
            <div className="warning">
              <div className="warning-title">Please Be Aware</div>
              <div className="warning-text">
                Any changes below will overwrite existing project data, without
                any option to reverse or undo. As such, please only alter values
                that you are familiar with.
              </div>
              <div className="download-wrapper">
                <button onClick={backUp} type="button">
                  Download Back Up
                </button>
              </div>
            </div>
            <form id="projectData-form">
              <div className="title">Edit Project</div>
              <div className="button-wrapper">
                <SaveButton />
              </div>

              <div className="input-wrapper">
                <div className="subtitle">Project Information</div>
                <ConfigInput
                  title={"Project Name"}
                  code={"projectName"}
                  value={projectData.projectName}
                  width={700}
                />
                <ConfigInput
                  title={"Project Description"}
                  code={"projectDescription"}
                  value={projectData.projectDescription}
                  width={700}
                />
                <ConfigInput
                  title={"Client"}
                  code={"client"}
                  value={projectData.client}
                  width={300}
                />
              </div>

              <div className="input-wrapper">
                <div className="subtitle">Project Method</div>
                <ConfigInput
                  title={'Either "ugf" or "bng"'}
                  code={"config.method"}
                  value={projectData.config.method}
                  width={250}
                />
              </div>

              <div className="input-wrapper">
                <div className="subtitle">Tree Canopy Sizes</div>
                <ConfigInput
                  title={"Small Trees"}
                  code={"config.trees.smallTreeCanopySize"}
                  value={projectData.config.trees.smallTreeCanopySize}
                  width={250}
                />
                <ConfigInput
                  title={"Medium Trees"}
                  code={"config.trees.mediumTreeCanopySize"}
                  value={projectData.config.trees.mediumTreeCanopySize}
                  width={250}
                />
                <ConfigInput
                  title={"Large Trees"}
                  code={"config.trees.largeTreeCanopySize"}
                  value={projectData.config.trees.largeTreeCanopySize}
                  width={250}
                />
              </div>
              <div className="input-wrapper">
                <div className="subtitle">Targets</div>
                {projectLevels.map((data, index) => (
                  <ConfigInput
                    type="project"
                    title={index}
                    code={"config.levels." + index + ".name"}
                    value={data[1].name}
                    width={250}
                    key={index}
                  />
                ))}
              </div>

              <div className="title">Edit Entities</div>
              <div className="button-wrapper">
                <SaveButton />
              </div>
              <div className="subtitle">Select Entity</div>
              <Select
                onChange={(e) => selectPlot(e)}
                className="dropdown"
                options={plotOptions}
                defaultValue={plotOptions[0]}
              />

              <div className="input-wrapper">
                <div className="subtitle">Setup</div>
                <div className="input-col">
                  <ConfigInput
                    title={"Entity Name"}
                    code={"entity.name"}
                    value={currentPlot?.name}
                    width={250}
                  />
                </div>
                <div className="input-col">
                  <ConfigInput
                    title={"Existing Tree Canopy"}
                    code={"entity.setup.existingTreeCanopy"}
                    value={currentPlot?.setup.existingTreeCanopy}
                    width={250}
                  />
                </div>
                <div className="input-col">
                  <ConfigInput
                    title={"Existing Biodiveristy Units"}
                    code={"entity.setup.existingBiodiversityUnits"}
                    value={currentPlot?.setup.existingBiodiversityUnits}
                    width={250}
                  />
                </div>
              </div>

              <div className="subtitle">KPIs</div>
              {currentPlot?.kpi.map((data, index) => (
                <div className="kpi-wrapper" key={data.name + index}>
                  <div className="subtitle">{data.name}</div>
                  <div>{KPIInputs(data.targets, index)}</div>
                </div>
              ))}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Configuration;
