import {useParams} from "react-router-dom";
import {AuthContext, DashContext} from '../utils/Context';
import React, { useContext, useEffect, useState } from 'react';
import Header from "../components/Header/Header";
import StatusPill from "../components/StatusPill/StatusPill";
import { AllKPICharts } from "../components/KPIChart/KPIChart";
import { DeveloperValueChart, LandscapeTypes, PlotComparisonChart, RadarChart, SocietalValueChart } from "../components/OtherCharts/OtherCharts";
import { InputToggle } from "../components/InputToggle/InputToggle";
import Sidebar from "../components/Sidebar/Sidebar";
import OptionsTable from "../components/OptionsTable/OptionsTable";
import SaveOption from "../components/SaveOption/SaveOption";
import {SameName, Warning} from "../components/Warning/Warning";
import { Legend } from "../components/Legend/Legend";

function Dashboard (){
    const {
        projectData, setProjectData, 
    } = useContext(AuthContext);

    const {id} = useParams();
    const [showTargets, setShowTargets] = useState(false)
    const [thisPlot, setPlot] = useState(false)
    const [baselinePlot, setBaselinePlot] = useState({});
    const [inputValues, setInputValues] = useState({})
    const [baseAreaValues, setBaseAreaValues] = useState({})
    const [areaValues, setAreaValues] = useState({})
    const [treeArray, setTreeArray] = useState([0,0,0,0])
    const [KPIVals, setKPIVals] = useState({
      "Urban Greening":0,
      "Biodiversity Change":0,
      "Tree Cover":0,
      "Trees Planted":0,
      "Greenspace":0,
      "Rainwater":0,
    })  
    // console.log(KPIVals)
    const [baseValues, setBaseValues] = useState()
    const [treemapData, setTreemapData] = useState([
      {x: "Not Landscaped", y: 1, fillColor: 'rgb(133,132,133)'},
      {x: "SuDS", y: 1, fillColor: 'rgb(59,151,195)'},
      {x: "Trees", y: 1, fillColor: 'rgb(132,167,40)'},
      {x: "Extensive Green Roof", y: 1, fillColor: 'rgb(141,179,43)'},
      {x: "Intensive Green Roof", y: 1, fillColor: 'rgb(150,190,45)'},
      {x: "Open Green Space", y: 1, fillColor: 'rgb(196,216,137)'},
      {x: "Green Wall", y: 1,fillColor: 'rgb(173,203,91)'}
    ])
    const [warning, setWarning] = useState(false)
    const [nameWarning, setNameWarning] = useState(false)
    const [warningContent, setWarningContent] = useState({
        type:"",
        message:"",
        position:""
    })
    
    useEffect(() => {
        let findPlot
        let defaultVersion
        
        if(!projectData){
            const localProject = JSON.parse(localStorage.getItem("project"))
            setProjectData(localProject)
            let type = localProject.config.method
            findPlot = {...localProject.entities.find(o => o.name === id)}
            setPlot(findPlot)
            defaultVersion = {...findPlot.versions.find(o => o.default === true)}
            setBaselinePlot({...defaultVersion})
            setBaseValues(defaultVersion[type])
            setInputValues(defaultVersion[type])
            setBaseAreaValues (defaultVersion['area'])
            setAreaValues (defaultVersion['area'])

        } else {
            let type = projectData.config.method
            findPlot = {...projectData.entities.find(o => o.name === id)}
            setPlot(findPlot)
            defaultVersion = {...findPlot.versions.find(o => o.default === true)}
            setBaselinePlot({...defaultVersion})
            setBaseValues(defaultVersion[type])
            setInputValues(defaultVersion[type])
            setBaseAreaValues (defaultVersion['area'])
            setAreaValues (defaultVersion['area'])
        } 

    },[projectData]);
    
    if(!projectData){
        return <></>
    }
    return (
        <DashContext.Provider value={{
            thisPlot, setPlot, 
            baselinePlot, setBaselinePlot,
            inputValues, setInputValues,
            baseAreaValues, setBaseAreaValues,
            areaValues, setAreaValues,
            treeArray, setTreeArray,
            KPIVals, setKPIVals,
            baseValues, setBaseValues,
            treemapData, setTreemapData,
            warning, setWarning,
            warningContent, setWarningContent,
            nameWarning, setNameWarning
        }}>
            <Header/>
            {warning?<Warning/>:""}
            {nameWarning?<SameName/>:""}
            <div className="background">
                <div className="wrapper">
                    <p className="subtitle">{projectData?.projectName}.</p>
                    <p className="title">{thisPlot?.name} design.</p>
                    <div className="dashboard">
                        <div className="meta-info-wrap">
                            <div className="meta-col">
                                <p className="meta-title">
                                    Lastest Option
                                </p>
                                <p className="meta-info">
                                    {baselinePlot?.name}
                                </p>
                            </div>
                            <div className="meta-col">
                                <p className="meta-title">
                                    Last Updated
                                </p>
                                <p className="meta-info">
                                    {baselinePlot?.lastUpdate}
                                </p>
                            </div>
                        </div>
                        
                        <div className="showTargets-toggle">
                            <InputToggle label="Show targets" toggled={false} onClick={()=>setShowTargets(!showTargets)}/>
                        </div>
                        
                        <div className="pill-header-wrap">
                            <div className="pill-header">Latest performance</div>
                            <div className="desc">Overview of KPI performance for selected primary option. Use the "Show targets" button to show or hide the target level met for each KPI. Please speak to your Hoare Lea advisor to set new targets</div>
                        </div>

                        
                        
                        <div className="status-pills">
                            {
                                baselinePlot?.performance?.map((data, index)=>(
                                    <StatusPill key={index} kpi={data} showTargets={showTargets}/>
                                ))
                            }
                        </div>
                        
                        <Legend />
                        

                        

                        
                        <br></br><br></br>
                        <p className="section-title">
                            Calculator
                        </p>
                        <div className="table-wrapper">
                            <div className="options-table-title">
                                Options
                            </div>
                            <div className="desc">
                                Saved design options. Speak to your Hoare Lea advisor to save new designs.
                            </div>
                            <OptionsTable />
                        </div>
                        <div className="calculator">
                            {!thisPlot?"":<Sidebar/>}
                            <div className="results-wrap">
                                <SaveOption/>
                                <PlotComparisonChart kpis={KPIVals} target={"Urban Greening"} />
                                <div className="results-pill">
                                    <div className="results-pill-title">
                                        KPI Performance
                                    </div>
                                    <div className="desc">
                                    KPI performance of current design compared to target thresholds. The dashed lines indicate the primary design performance. Please note these are projections and respective consultants should be used to calculate the true value.
                                    </div>
                                    <AllKPICharts/>
                                </div>
                                
                                <div className="results-pill">
                                    <div className="results-pill-title">
                                        Landscape Types
                                    </div>
                                    <div className="desc">
                                        The area of each Nature-based Solution type as a percentage of total site area. Please note however the sum of these percentages will not always total 100% owing to the inclusion of overlapping habitats and green walls.
                                    </div>
                                    <LandscapeTypes />
                                </div>
                                <div className="results-pill">
                                    <div className="results-pill-title">
                                        Habitat Maturity <span style={{color:"#ccc"}}>(Experimental)</span>
                                    </div>
                                    <div className="desc">
                                        The landscpae design's wider value creation, displayed on a scale of habitat maturity from bare rock (centre) to a forest (outer edge). The current design (soild grey) can be compared to the default design (dashed). 
                                    </div>
                                    <RadarChart years={60}/>
                                </div>
                                <div className="results-pill touching">
                                    <div className="results-pill-title">
                                        Developer Value Creation
                                    </div>
                                    <div className="desc">
                                        Projection of the cumulative avoided carbon cost attributable to the amount of carbon sequestrated. Compares current design against default design selected.
                                    </div>
                                    <div className="graph-year-wrap">
                                        <p className="year-tag" >Year: </p>
                                        <p className="currency" pos="0" id="dev-value-year">0</p>
                                    </div>
                                    <DeveloperValueChart years={60} startYear={2023} rate={1.03}/>
                                </div>
                                <div className="results-pill-wrap">
                                    <div className="mini-results-pill">
                                        <p className="subtitle">Median Estimate</p>
                                        <div className="currency main" id="dev-value-med">0</div>
                                        
                                    </div>
                                    <div className="mini-results-pill grey">
                                        <p className="subtitle">Lower Estimate</p>
                                        <p className="currency" id="dev-value-low">0</p>
                                        <p className="subtitle">Upper Estimate</p>
                                        <p className="currency"id="dev-value-high">0</p>
                                    </div>
                                    <div className="mini-results-pill grey">
                                        <p className="subtitle">Baseline Estimate</p>
                                        <p className="currency" id="dev-value-base">0</p>
                                        <p className="subtitle">Change from Baseline</p>
                                        <div className="currency" id="dev-value-change">0</div>
                                    </div>
                                </div>
                                <div className="results-pill touching">
                                    <div className="results-pill-title">
                                        Societal Value Creation
                                    </div>
                                    <div className="desc">
                                    Projection of the cumulative avoid cost associated with carbon damages to agricultural productivity, human health and property. Compares current design against defualt design selected.
                                    </div>
                                    <div className="graph-year-wrap">
                                        <p className="year-tag" >Year: </p>
                                        <p className="currency" pos="0" id="soc-value-year">0</p>
                                    </div>
                                    <SocietalValueChart years={40} startYear={2023} />
                                </div>
                                <div className="results-pill-wrap">
                                    <div className="mini-results-pill">
                                        <p className="subtitle">Median Estimate</p>
                                        <div className="currency main" id="soc-value-med">0</div>
                                    </div>
                                    <div className="mini-results-pill grey">
                                        <p className="subtitle">Lower Estimate</p>
                                        <p className="currency" id="soc-value-low">0</p>
                                        <p className="subtitle lower">Upper Estimate</p>
                                        <p className="currency"id="soc-value-high">0</p>
                                    </div>
                                    <div className="mini-results-pill grey">
                                        <p className="subtitle">Baseline Estimate</p>
                                        <p className="currency" id="soc-value-base">0</p>
                                        <p className="subtitle lower">Change from Baseline</p>
                                        <div className="currency" id="soc-value-change">0</div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashContext.Provider>
    );
}

export default Dashboard