import React, { useContext, useEffect} from 'react';
import {AuthContext} from '../utils/Context';
import {useNavigate} from 'react-router-dom';
import Header from '../components/Header/Header';
import PlotCard from '../components/PlotCard/PlotCard';
import {getCookie} from './../utils/Cookies'
import PlotTable from '../components/PlotTable/PlotTable';
import { addUnits, renameFactors } from '../utils/Basic';
import { Legend } from '../components/Legend/Legend';

function Projects() {
    const {thisAuth, projectData, setProjectData} = useContext(AuthContext);
    const navigate = useNavigate();
    const loadJSON = async (location) => {
        fetch('/project-data/'+location, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then((response) => response.json())
        .then((responseJson) => {
            setProjectData(responseJson)
            localStorage.setItem("project",JSON.stringify(responseJson))
        })
    }


    let dataCookie = getCookie("name")
    
    useEffect(() => {
        if(thisAuth?.project === null){
            navigate('/');
        };
        loadJSON(dataCookie)
    }, [thisAuth, dataCookie, navigate]);
    
    if(!projectData){
        return <></>
    }

    let masterplanEntity
    let masterplanDiv
    masterplanEntity = projectData.entities.find((obj => obj.masterplan === true))
    if(masterplanEntity !== undefined){
        masterplanDiv = <>
            <PlotCard data={masterplanEntity}/>
        </>
    }

    
    let plotEntities = {...projectData}.entities.filter(obj => obj.masterplan === false)

    // let plotDiv = <>
    //     {
    //         plotEntities?.map((data, index)=> (
    //             <PlotCard data={data} key={index}/>
    //         ))
    //     }
    // </>

    return (
        <>
            <Header />
            <div className="background">
                <div className="wrapper">
                    <p className="title">{projectData?.projectName}</p>
                    <p className="project-description">{projectData?.projectDescription}</p>
                    

                    <p className='section-title'>
                        Project Overview
                    </p>
                    <p className='help-text'>
                    Summary of masterplan and plot scores against key performance indicators. Please select a masterplan or plot to view individual dashboards and optioneer landscaping designs.
                    </p>
                    <div className='legend-wrap'>
                        <Legend />
                    </div>
                    
                    {/* <div className="plot-card-wrapper">
                        {masterplanDiv}
                    </div>
                    <p className="plot-title">Plots</p>
                    <div className='plot-card-wrapper'>
                        
                        {plotDiv}
                    </div> */}
                    <div className='plot-table-wrap'>
                        <div className='header-row'>
                            <div className='header-cell cell first-column'>
                                Name 
                            </div>
                            <div className='header-cell cell'>
                                Last Updated 
                            </div>
                            {
                                masterplanEntity?.versions[0]?.performance?.map((data, index)=>(
                                    <div className='header-cell cell' key={index}>{renameFactors(data.name)} ({addUnits(data.name)})</div>
                                ))
                            }
                        </div>
                        <div className='break-row'>Masterplan</div>
                        <PlotTable data={masterplanEntity}/>
                        <div className='break-row'>Plots</div>
                        {
                            plotEntities.map((data)=>(
                                <PlotTable data={data}/>
                            ))
                        }

                    </div>
                </div>
            </div>
        </>
        
    );
  }
  
  export default Projects;
  