import AreaInput from "../AreaInput/AreaInput";
import { AuthContext, DashContext } from "../../utils/Context";
import { getCookie } from "../../utils/Cookies";
import React, { useContext } from "react";
import './AreaInputs.css'
import {
  TreeCanopy,
  TreesPlanted,
  AccessiblePlanting,
  UrbanGreening,
  BiodiversityNetGain,
  calcRainwaterManagement,
} from "../../utils/Calculations";

import {
  UpdateDeveloperValueMetrics,
  UpdateSocialValueMetrics,
} from "../OtherCharts/OtherCharts";

let limitFunction = null;

function AreaInputs() {

  const { projectData } = useContext(AuthContext);

  const {
    thisPlot,
    baseValues,
    baseAreaValues, 
    setKPIVals,
    inputValues, 
    areaValues,
    setAreaValues,
    treeArray,
    setTreeArray,
  } = useContext(DashContext);

  let adminCookie = getCookie("admin");
  if (adminCookie === "false") {
    return (
      <div>
        <p className="input-title">Total Area</p>
        <p className="input-value">{baseAreaValues?.totalArea}</p>
        <p className="input-title">Public Realm</p>
        <p className="input-value">{baseAreaValues?.publicRealm}</p>
        <p className="input-title">Sealed Surface</p>
        <p className="input-value">{baseAreaValues?.sealedSurface}</p>
      </div>
    );
  }

  const RunCalcs = (key, value) => {
    if (limitFunction) {
      clearTimeout(limitFunction);
    }

    limitFunction = setTimeout(() => {
      const newAreaValues = JSON.parse(JSON.stringify(areaValues));
      newAreaValues[key] = value;
      setAreaValues(newAreaValues);

      const thisTreeArray = TreeCanopy(inputValues, newAreaValues, projectData, thisPlot);
      setTreeArray(thisTreeArray);
      const updatedPlot = JSON.parse(JSON.stringify(thisPlot));
      setKPIVals({
        "Urban Greening": UrbanGreening(
          inputValues,
          newAreaValues, 
          thisTreeArray[2],
          thisTreeArray[3]
        ),
        "Biodiversity Change": BiodiversityNetGain(
          inputValues,
          thisTreeArray[2],
          thisTreeArray[3],
          updatedPlot,
          true
        ),
        "Tree Cover": thisTreeArray[1],
        "Trees Planted": TreesPlanted(inputValues),
        Greenspace: AccessiblePlanting(
          inputValues,
          newAreaValues,
          thisTreeArray[0]
        ),
        Rainwater: calcRainwaterManagement(
          inputValues,
          newAreaValues,
          thisTreeArray[2],
          thisTreeArray[3],
          updatedPlot
        ),
      });
      UpdateSocialValueMetrics(
        inputValues,
        treeArray[2],
        treeArray[3],
        40,
        2023,
        thisPlot,
        projectData,
        baseValues,
        baseAreaValues
      );
      UpdateDeveloperValueMetrics(
        inputValues,
        treeArray[2],
        treeArray[3],
        60,
        2023,
        thisPlot,
        projectData,
        1.03,
        baseValues,
        baseAreaValues
      );
    }, 20);
  };


  const handleAreaChange = (key, newValue) => {
    const newAreaValues = { ...areaValues };
    newAreaValues[key] = Number(newValue);
    setAreaValues(newAreaValues);
  };

  return (
    <div>
      <AreaInput
        title="Total Area"
        areaKey="totalArea"
        initialValue={baseAreaValues?.totalArea}
        onChange={handleAreaChange}
        onCalculation={RunCalcs}
      />
      <AreaInput
        title="Public Realm"
        areaKey="publicRealm"
        initialValue={baseAreaValues?.publicRealm}
        onChange={handleAreaChange}
        onCalculation={RunCalcs}
      />
      <AreaInput
        title="Sealed Surface"
        areaKey="sealedSurface"
        initialValue={baseAreaValues?.sealedSurface}
        onChange={handleAreaChange}
        onCalculation={RunCalcs}
      />
    </div>
  );
}

export default AreaInputs;
