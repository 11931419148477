import { AuthContext, DashContext } from '../../utils/Context';
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    RadialLinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Tooltip,
    Legend
} from 'chart.js';
import ApexChart from 'react-apexcharts'
import React, { useContext } from 'react';
import annotationPlugin from 'chartjs-plugin-annotation';
import { BiodiversityNetGain, DefraBNGMetrics, LandscapeAreas, SocietalValueCalc, TreeCanopy, UrbanGreening, UrbanGreeningBreakdown, benefitArray, benefitNames, commas, generateRadarThresholds, round2DP, totalSeqOverYear } from '../../utils/Calculations';
import {TreemapController, TreemapElement} from 'chartjs-chart-treemap';
import { Line, Radar, Bar } from 'react-chartjs-2';
import { DeveloperValueCalc } from '../../utils/Calculations';
import { round1000 } from '../../utils/Basic';

/**
 * Chartjs Plugin to draw a line across intersection on chart
 * @date 8/21/2023 - 2:28:58 PM
 *
 * @type {{ id: string; afterDraw: (chart: any, easing: any) => void; }}
 */
const plugin = {
    id: 'uniqueid5', //typescript crashes without id
    afterDraw: function (chart, easing) {
        if(chart.config.options.plugins.intersectLine){
            if (chart.tooltip._active && chart.tooltip._active.length) {
                const activePoint = chart.tooltip._active[0];
                const ctx = chart.ctx;
                const x = activePoint.element.x;
                const topY = chart.scales.y.top;
                const bottomY = chart.scales.y.bottom;
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, topY);
                ctx.lineTo(x, bottomY);
                ctx.lineWidth = 1;
                ctx.strokeStyle = '#333';
                ctx.stroke();
                ctx.restore();
            }
        }
        
    }
}

Chart.register(
    CategoryScale,
    RadialLinearScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    annotationPlugin,
    TreemapController,
    TreemapElement,
    Legend,
    plugin
);
Chart.defaults.font.family="Lato"

const mainColor = "rgba(129,176,192, 0.8)"
const fadedColor = "rgba(129,176,192, 0.1)"
ApexChart.chart = { fontFamily: "Lato" }

/**
 * Creates the data structure and colours for the UGF landscape area treemap
 * @date 8/21/2023 - 2:28:58 PM
 *
 * @export
 * @param {{}} input Object of input values for calc
 * @param {{}} area Object of area values for calc
 * @param {Number} treesUnder Total trees with small pits
 * @param {Number} treesOver Total trees with big pits
 * @returns {[{x:landscape, y:value, fillColor:color}]} 
 */
export function prepLandscapeGraph (input, area, treesUnder, treesOver){
    const trees=treesUnder+treesOver
    const sumVals = obj => Object.values(obj).reduce((a, b) => a + b, 0);
    const extGreenRoof = sumVals(input?.extensiveGreenRoof)
    const intGreenRoof = sumVals(input?.intensiveGreeRoof)
    const GreenSpace = sumVals(input?.openGreenSpace)
    const SuDS = sumVals(input?.SuDS)
    let notLandspaced = area.totalArea - extGreenRoof - intGreenRoof - trees - SuDS - GreenSpace
    if(notLandspaced<0){
        notLandspaced=0
    }
    return [
            {x: "Not Landscaped", y: notLandspaced, fillColor: 'rgb(133,132,133)'},
            {x: "SuDS", y: SuDS, fillColor: 'rgb(59,151,195)'},
            {x: "Trees", y: trees, fillColor: "rgb(132,167,40)"},
            {x: "Extensive Green Roof", y: extGreenRoof, fillColor: "rgb(141,179,43))"},
            {x: "Intensive Green Roof", y: intGreenRoof, fillColor: "rgb(150,190,45)"},
            {x: "Open Green Space", y: GreenSpace, fillColor: "rgb(196,216,137)"},
            {x: "Green Wall", y: input.greenwall.greenwall,fillColor: "rgb(173,203,91)"}
    ]
}

/**
 * Uses state options/series to create ApexChart treemap
 * @date 8/21/2023 - 2:28:58 PM
 *
 * @export
 * @returns {*} Div landscape area chart
 */
export function LandscapeTypes() {
    const {treemapData, inputValues} = useContext(DashContext);

    if(!("treesOverTwoThirds" in inputValues)){
        return <></>
    }
    const series = [
        {
            name: "xx",
            data: treemapData
        }
    ];
    const options = {
        plotOptions: {
            treemap: {
              distributed: true
            }
        },
        chart: {
            toolbar: {
                show:false
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                fontFamily: "Lato",
                color:"#333"
            },
        },
        tooltip:{
            style: {
                fontSize: '12px',
                fontFamily: undefined
            },
            x: {
                show: true,
                formatter: undefined,
            }
        }
    }
    return (
        <div id="landscape-type-wrap">
            <ApexChart options={options} series={series} type="treemap" width="600"  />
        </div>
    );
}

/**
 * Creats ChartJS line chart showing value to developer
 * @date 8/21/2023 - 2:28:58 PM
 *
 * @typedef {object} Props
 * @property  {number} years Number of years for calculation
 * @property  {number} startYear Starting year for value calc
 * @property  {number} rate Rate of inflation change as a whole number e.g. 1.03 = 3%
 * 
 * @export
 * @param {Props} props
 * @returns {*} Div with ChartJS graph 
 */
export function DeveloperValueChart(props){
    let years = props.years
    let startYear = props.startYear
    let rate=props.rate
    const {inputValues, treeArray, thisPlot} = useContext(DashContext);

    const {projectData} = useContext(AuthContext);
    if(!("treesOverTwoThirds" in inputValues)){
        return ""
    }
    let defaultPlot = {...thisPlot.versions.find(o => o.default === true)}
    let method = projectData.config.method
    let defaultValues = defaultPlot[method]
    let defaultAreaValues = defaultPlot['area']

    

    let treesUnder = treeArray[2]
    let treesOver = treeArray[3]
    let results = DeveloperValueCalc(inputValues, treesUnder, treesOver, years, startYear, rate)
    const oldTrees = TreeCanopy(defaultValues, defaultAreaValues, projectData, thisPlot)
    let old = DeveloperValueCalc(defaultValues, oldTrees[2], oldTrees[3], years, startYear, rate)

    const options = {
        responsive: true,
        elements:{
            point:{
                borderWidth: 0,
                radius: 0,
            },
            line: {
                fill: '-1'
            }
        },
        plugins: {
            intersectLine:true,
            legend: {
                display:false,
                position: 'top',
            },
            title: {
                display: false,
            },
            tooltip:{
                backgroundColor:"rgba(0,0,0,0)",
                callbacks: {
                    title: (context) => {
                        return ""
                    },
                    label: (context) => {
                        document.getElementById("dev-value-med").innerHTML = "£"+ commas(round1000(results.medium[context.dataIndex])) 
                        document.getElementById("dev-value-low").innerHTML = "£"+ commas(round1000(results.low[context.dataIndex]))
                        document.getElementById("dev-value-high").innerHTML = "£"+ commas(round1000(results.high[context.dataIndex]))
                        document.getElementById("dev-value-base").innerHTML = "£"+ commas(round1000(old.medium[context.dataIndex]))
                        document.getElementById("dev-value-change").innerHTML = "£"+ commas(round1000(results.medium[context.dataIndex])-round1000(old.medium[context.dataIndex]))
                        document.getElementById("dev-value-year").innerHTML = context.label
                        document.getElementById("dev-value-year").setAttribute("pos",context.dataIndex)
                        return ""
                    }
                },
            }
        },
        scales:{
            y:{
                ticks:{
                    
                }
            }
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
    };

    const labels = []

    for(let i=startYear; i<startYear+years; i++){
        labels.push(i.toString())
    }
    
    const data = {
        labels,
        datasets: [
            {
                label: 'Low Bound',
                fill: 'origin',
                data: results.low,
                borderColor: fadedColor,
                backgroundColor: 'rgba(0, 0, 0, 0)',
            },{
                label: 'High Bound',
                data: results.high,
                borderColor: fadedColor,
                backgroundColor: fadedColor,
            },
            {
                label: 'Projected Value',
                fill: false,
                data: results.medium,
                borderColor: mainColor,
                backgroundColor: 'rgba(99, 255, 132, 0)',
            },
            {
                label: 'Baseline Value',
                data: old.medium,
                fill: false,
                borderDash: [5,3],
                borderColor: 'rgba(99, 90, 90, 0.6)',
                backgroundColor: 'rgba(99, 90, 90, 0.2)',
            },
        ],
    };

    return <Line options={options} data={data} />;
}

/**
 * Updates the supporting metrics and text under the Dev Value chart
 * @date 8/21/2023 - 2:28:58 PM
 *
 * @export
 * @param {{}} input Object of input values
 * @param {Number} treesUnder Total trees with small pits
 * @param {Number} treesOver Total trees with big pits
 * @param {Number} years Number of years to project value acrrual
 * @param {Number} startYear Year to begin inflation calc
 * @param {{}} thisPlot Object of entity data
 * @param {{}} projectData Overall project data
 * @param {Number} rate Inflation rate as whole number e.g. 1.03 = 3%
 * @param {{}} baseValues Default option values for reference line
 */
export function UpdateDeveloperValueMetrics(input, treesUnder, treesOver, years, startYear, thisPlot, projectData, rate, baseValues, baseAreaValues){
    let results = DeveloperValueCalc(input, treesUnder, treesOver, years, startYear, rate)
    const oldTrees = TreeCanopy(baseValues, baseAreaValues, projectData, thisPlot)
    let old = DeveloperValueCalc(baseValues, oldTrees[2], oldTrees[3], years, startYear, rate)
    let pos = document.getElementById("dev-value-year").getAttribute("pos")
    document.getElementById("dev-value-med").innerHTML = "£"+ commas(round1000(results.medium[pos])) 
    document.getElementById("dev-value-low").innerHTML = "£"+ commas(round1000(results.low[pos]))
    document.getElementById("dev-value-high").innerHTML = "£"+ commas(round1000(results.high[pos]))
    document.getElementById("dev-value-base").innerHTML = "£"+ commas(round1000(old.medium[pos]))
    let change = 0
    change = round1000(results.medium[pos])-round1000(old.medium[pos])
    document.getElementById("dev-value-change").innerHTML = "£"+ commas(change)
}

/**
 * Creats ChartJS line chart showing value to society
 * @date 8/21/2023 - 2:28:58 PM
 * 
 * @typedef {object} Props
 * @property  {number} years Number of years for calculation
 * @property  {number} startYear Starting year for value calc
 * @property  {number} rate Rate of inflation change as a whole number e.g. 1.03 = 3%
 * 
 * @export
 * @param {Props} props
 * @returns {*} Div with ChartJS graph 
 */
export function SocietalValueChart(props){
    const {projectData} = useContext(AuthContext);
    const {inputValues, treeArray, thisPlot} = useContext(DashContext);

    if(!("treesOverTwoThirds" in inputValues)){
        return ""
    }
    let defaultPlot = {...thisPlot.versions.find(o => o.default === true)}
    let method = projectData.config.method
    let defaultValues = defaultPlot[method]
    let defaultAreaValues = defaultPlot['area']
    let years = props.years
    let startYear = props.startYear
    if(!("treesOverTwoThirds" in inputValues)){
        return ""
    }

    let treesUnder = treeArray[2]
    let treesOver = treeArray[3]
    let results = SocietalValueCalc(inputValues, treesUnder, treesOver, years, startYear)
    const oldTrees = TreeCanopy(defaultValues, defaultAreaValues, projectData, thisPlot)
    let old = SocietalValueCalc(defaultValues, oldTrees[2], oldTrees[3], years, startYear)

    let labels = results.year
    const options = {
        responsive: true,
        elements:{
            point:{
                borderWidth: 0,
                radius: 0,
            },
            line: {
                fill: '-1'
            }
        },
        hover: {
            mode: 'index',
            intersect: false,
          },
        plugins: {
            intersectLine:true,
            legend: {
                display:false,
                position: 'top',
            },
            title: {
                display: false,
            },
            tooltip:{
                backgroundColor:"rgba(0,0,0,0)",
                callbacks: {
                    title: (context) => {
                        return ""
                    },
                    label: (context) => {
                        document.getElementById("soc-value-med").innerHTML = "£"+ commas(round1000(results.medium[context.dataIndex]))
                        document.getElementById("soc-value-low").innerHTML = "£"+ commas(round1000(results.low[context.dataIndex]))
                        document.getElementById("soc-value-high").innerHTML = "£"+ commas(round1000(results.high[context.dataIndex]))
                        document.getElementById("soc-value-base").innerHTML = "£"+ commas(round1000(old.medium[context.dataIndex]))
                        document.getElementById("soc-value-change").innerHTML = "£"+ commas(round1000(results.medium[context.dataIndex])-round1000(old.medium[context.dataIndex]))
                        document.getElementById("soc-value-year").innerHTML = context.label
                        document.getElementById("soc-value-year").setAttribute("pos",context.dataIndex)
                        return ""
                    }
                },
            }
        },
        scales:{
            y:{
                ticks:{
                },
            }
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
    };
    const data = {
        labels,
        datasets: [
            {
                label: 'Low Bound',
                fill: 'origin',
                data: results.low,
                borderColor: fadedColor,
                backgroundColor: 'rgba(99, 255, 132, 0)',
            },{
                label: 'High Bound',
                data: results.high,
                borderColor: fadedColor,
                backgroundColor: fadedColor,
            },
            {
                label: 'Projected Value',
                fill: false,
                data: results.medium,
                borderColor: mainColor,
                backgroundColor: 'rgba(99, 255, 132, 0)',
            },
            {
                label: 'Baseline Value',
                data: old.medium,
                fill: false,
                borderDash: [5,3],
                borderColor: 'rgba(99, 90, 90, 0.6)',
                backgroundColor: 'rgba(99, 90, 90, 0.2)',
            },
            
            
            
        ],
    };

    return <Line options={options} data={data} plugins={plugin}/>;

}

/**
 * Updates the supporting metrics and text under the Social Value chart
 * @date 8/21/2023 - 2:28:58 PM
 *
 * @export
 * @param {{}} input Object of input values
 * @param {Number} treesUnder Total trees with small pits
 * @param {Number} treesOver Total trees with big pits
 * @param {Number} years Number of years to project value acrrual
 * @param {Number} startYear Year to begin inflation calc
 * @param {{}} thisPlot Object of entity data
 * @param {{}} projectData Overall project data
 * @param {{}} baseValues Default option values for reference line
 */
export function UpdateSocialValueMetrics(input, treesUnder, treesOver, years, startYear, thisPlot, projectData, baseValues, baseAreaValues){
    let results = SocietalValueCalc(input, treesUnder, treesOver, years, startYear)
    const oldTrees = TreeCanopy(baseValues, baseAreaValues, projectData, thisPlot)
    let old = SocietalValueCalc(baseValues, oldTrees[2], oldTrees[3], years, startYear)
    let pos = document.getElementById("soc-value-year").getAttribute("pos")

    document.getElementById("soc-value-med").innerHTML = "£"+ commas(round1000(results.medium[pos])) 
    document.getElementById("soc-value-low").innerHTML = "£"+ commas(round1000(results.low[pos]))
    document.getElementById("soc-value-high").innerHTML = "£"+ commas(round1000(results.high[pos]))
    document.getElementById("soc-value-base").innerHTML = "£"+ commas(round1000(old.medium[pos]))
    document.getElementById("soc-value-change").innerHTML = "£"+ commas(round1000(results.medium[pos])-round1000(old.medium[pos]))
}

/**
 * Caps a provided number at 1
 * @date 8/21/2023 - 2:28:58 PM
 *
 * @export
 * @param {number} num
 * @returns {number} Return the number if under 1, or 1
 */
export function capVal (num){
    if(num>1){
        return 1
    } else {
        return num
    }
}

export function minVal(n){
    if(n<0){
        return 0
    } else {
        return n
    }
}

export function bestBNGMetrics(arr){
    let array = []
    for(var x in arr){
        let z = 0.0001;
        for(var y in arr[x]){
            z = z*arr[x][y]
        }
        array.push(z)
    }
    return Math.max(...array);
}

/**
 * Creates a ChartJS radar graph for the benefits the design is creating
 * @date 8/21/2023 - 2:28:58 PM
 *  
 * @typedef {object} Props
 * @property  {number} years Number of years for calculation
 * 
 * @export
 * @param {Props*} props
 * @returns {*} Div with ChartJS graph
 */
export function RadarChart(props){
    const {projectData} = useContext(AuthContext);
    const {inputValues, areaValues, treeArray, thisPlot, baseValues, baseAreaValues} = useContext(DashContext);
    if(!("treesOverTwoThirds" in inputValues)){
        return ""
    }
    const totalArea = areaValues.totalArea
    const thresholdArray = generateRadarThresholds()
    const thresholds = thresholdArray[0]
    const maxes = thresholdArray[1]
    let thisPerformance = benefitArray(inputValues, treeArray[2], treeArray[3], thisPlot)
    const oldTrees = TreeCanopy(baseValues, baseAreaValues, projectData, thisPlot)
    let baselinePerformance = benefitArray(baseValues, oldTrees[2], oldTrees[3], thisPlot)
    
    // const thresholdColour = [
    //     "rgba(128,201,4,1)",
    //     "rgba(115,181,4,1)",
    //     "rgba(102,161,3,1)",
    //     "rgba(90,141,3,1)",
    //     "rgba(77,121,2,1)"
    // ]
    const thresholdColour = [
        "rgb(196,216,137)",
        "rgb(173,203,91)",
        "rgb(150,190,45)",
        "rgb(141,179,43)",
        "rgb(132,167,40)",
    ]
    
    let bestBNG = bestBNGMetrics(DefraBNGMetrics)
    let maxBNG = bestBNG * totalArea

    let labels = [];
    let length;
    let datasets = [];

    for(let key in benefitNames){
        labels.push(benefitNames[key])
        length = thresholds[key].length
        maxes[key] = round2DP(maxes[key]*totalArea)
        baselinePerformance[key] = capVal(round2DP(baselinePerformance[key]/maxes[key]))
        thisPerformance[key] = capVal(round2DP(thisPerformance[key]/maxes[key]))
    }

    const thisPerformanceData = Object.values(thisPerformance)
    const thisBaselineData = Object.values(baselinePerformance)
    for(let i = 0; i<length; i++){
        let outerBorderWidth=1;
        let outerBorderCol="rgba(255,255,255,0.4)";
        if(i===length-1){
            outerBorderWidth=1
            // outerBorderCol="#652a0E"
        }

        let array = []
        for(let key in benefitNames){
            array.push(thresholds[key][i])
        }
        let thisObj = {
            label: i,
            data: array,
            backgroundColor: thresholdColour[i],
            borderWidth:outerBorderWidth,
            borderColor:outerBorderCol,
            pointHoverRadius:0,
            pointHitRadius:0,
            pointRadius:0,
            lineTension: 0.36,
            order:i+3,
        }
        datasets.push(thisObj)
    }
    datasets.push({
        label:"Current Design",
        data: thisPerformanceData,
        fill:false,
        pointRadius:5,
        pointBackgroundColor:"rgba(200,200,200)",
        backgroundColor:'rgba(0,0,0,0)',
        borderWidth:3,
        borderColor:"rgba(134,133,134)",
        order:1
    })

    datasets.push({
        label:"Baseline Design",
        data: thisBaselineData,
        fill:false,
        pointRadius:5,
        pointBackgroundColor:"rgba(0,0,0,0.5)",
        backgroundColor:'rgba(0,0,0,0)',
        borderWidth: 3,
        borderColor: 'rgba(0,0,0,0.3)',
        borderDash:[5,3],
        order:1
    })

    //UGF and BNG Metrics
    const thisUGF = UrbanGreening(inputValues, areaValues, treeArray[2], treeArray[3])
    const oldUGF =  UrbanGreening(baseValues, baseAreaValues, oldTrees[2], oldTrees[3])
    const thisBNG = minVal(round2DP(BiodiversityNetGain(inputValues, treeArray[2], treeArray[3], thisPlot, false)/maxBNG))
    const oldBNG =  minVal(round2DP(BiodiversityNetGain(baseValues, oldTrees[2], oldTrees[3], thisPlot, false)/maxBNG))
    // console.log(BiodiversityNetGain(inputValues, treeArray[2], treeArray[3], thisPlot, true))
    let UFGVals = [0.2,0.4,0.6,0.8,1,capVal(thisUGF), capVal(oldUGF)]
    let BNGVals = [0.2,0.4,0.6,0.8,1,capVal(thisBNG), capVal(oldBNG)]
    labels.push(["Urban Greening","Factor"],["Biodiveristy","Net Gain"],["Carbon","Sequestered"])

    //Sequestration Metric
    const period = props.years
    let seqRates = totalSeqOverYear(period)
    let totalSeq = round2DP(Math.max(...Object.values(seqRates))*totalArea)
    const newAreas = LandscapeAreas(inputValues, treeArray[2], treeArray[3])
    const oldAreas = LandscapeAreas(baseValues, oldTrees[2], oldTrees[3])
    let oldSeq = {}
    let thisSeq = {}
    for(let key in seqRates){
        thisSeq[key]= seqRates[key]*newAreas[key]
        oldSeq[key]= seqRates[key]*oldAreas[key]
    }


    function numberLimiter(num){
        num>1? num = 1 : num = num
        return num
    }

    let oldSeqVal = numberLimiter(round2DP((Object.values(oldSeq).reduce((sum, num) => sum + num))/totalSeq))
    let thisSeqVal = numberLimiter(round2DP((Object.values(thisSeq).reduce((sum, num) => sum + num))/totalSeq))
    let seqValues = [0.2,0.4,0.6,0.8,1,thisSeqVal,oldSeqVal]

    for(let obj in datasets){
        datasets[obj].data.push(UFGVals[obj])
        datasets[obj].data.push(BNGVals[obj])
        datasets[obj].data.push(seqValues[obj])
    }



    const options = {
        responsive: true,
        scales:{
            r:{
                max:1,
                min:0,
                ticks:{
                    display:false,
                    z:99
                },
                grid: {
                    circular: true
                 },
                 pointLabels:{
                    font:{
                        size:"15px"
                        
                    },
                    padding:15,
                 }
            }
        },
        interaction: {
            intersect: true,
            // mode: 'index',
        },
        plugins:{
            legend:{
                display:false
            }
        }
    }
    
    const data = {
        labels: labels,
        datasets: datasets
    }
    
    return <Radar data={data} options={options}/>
}

function createDiagonalPattern(color = 'black') {
    // create a 10x10 px canvas for the pattern's base shape
    let shape = document.createElement('canvas')
    shape.width = 10
    shape.height = 10
    // get the context for drawing
    let c = shape.getContext('2d')
    // draw 1st line of the shape 
    c.strokeStyle = color
    c.beginPath()
    c.moveTo(3, 0)
    c.lineTo(10, 7)
    c.stroke()
    // draw 2nd line of the shape 
    c.beginPath()
    c.moveTo(0, 7)
    c.lineTo(3, 10)
    c.stroke()
    // create the pattern from the shape
    return c.createPattern(shape, 'repeat')
  }

export function PlotComparisonChart(props){
    const {inputValues, areaValues, treeArray, thisPlot} = useContext(DashContext);
    if(!thisPlot){
        return ""
    }
    if(thisPlot["masterplan"]){
        return ""
    }

    const landColours = {
        "SuDS": 'rgb(59,151,195)',
        "trees": "rgb(132,167,40)",
        "extensiveGreenRoof": "rgb(141,179,43)",
        "intensiveGreeRoof": "rgb(150,190,45)",
        "openGreenSpace": "rgb(196,216,137)",
        "greenwall": "rgb(173,203,91)"
    }

    const fullNames = {
        "SuDS": 'SuDS',
        "trees": "Trees",
        "extensiveGreenRoof": "Extensive Green Roof",
        "intensiveGreeRoof": "Intensive Green Roof",
        "openGreenSpace": "Open Green Space",
        "greenwall": "Greenwall"
    }

    

    const currentPerformance = props.kpis[props.target]
    const targetKPI = thisPlot.kpi.find(o => o.name === props.target)
    const targetPerformance = targetKPI.targets.find(o=>o.name === "Baseline").value
    const UGFBreakdown = UrbanGreeningBreakdown(inputValues, areaValues, treeArray[2], treeArray[3])
    
    let deficitVal = 0
    if(currentPerformance < targetPerformance){
        deficitVal = targetPerformance - currentPerformance
    }

    let datasets = []
    for(let obj in UGFBreakdown){
        datasets.push(
            {
                label:fullNames[obj],
                data:[UGFBreakdown[obj]],
                backgroundColor:landColours[obj]
            }
        )
    }
    // datasets.push(
    //     {
    //         label:'Performance',
    //         data:[currentPerformance],
    //         backgroundColor:"rgba(150,190,45,0.9)"
    //     }
    // )

    datasets.push(
        {
            label:'Deficit',
            data:[deficitVal],
            backgroundColor:createDiagonalPattern('rgba(222, 93, 93, 0.9)'),
            borderColor:"#e55",
            borderWidth: 2
        }
    )


    let options = {
        indexAxis: 'y',
        layout: {
            padding: {
                bottom:0,
                top:35
            }
        },
        elements: {
            bar: {
                borderWidth: 0,
            },
        },
        barPercentage: 0.4,
        categoryPercentage: 1,
        responsive: true,
        maintainAspectRatio:false,
        scales: {
            x: {
                stacked: true,
                min:0,
                max:targetPerformance*1.2,
                ticks:{
                    display:true,
                }
                },
            y: {
                stacked: true,
            },
        },
        plugins: {
            legend: {
                display: true,
                position:'bottom',
                labels:{
                    boxWidth:20,
                    boxHeight:20,
                    useBorderRadius: true,
                    borderRadius:3,
                    padding:25
                },
                title:{
                    display:false,
                    padding:5
                },
                onClick: function(event, legendItem) {}
            },
            title: {
                display: false,
            },
            tooltip:{
                enabled: true
            },
            annotation: {
                clip: false,
                annotations: {
                    line1: {
                        type: 'line',
                        xMin: targetPerformance,
                        xMax: targetPerformance,
                        borderWidth: 3,
                        borderColor: 'rgba(0,0,0,0.6)',
                        borderDash:[5,3],
                        label:{
                            display:false,
                            content:'Target: '+targetPerformance,
                            backgroundColor: 'rgba(0,0,0,0.6)',
                            borderDash:[5,3],
                            position:'end',
                            yAdjust:-20,
                            font:{
                                size:14
                            }
                        },
                        enter({element}, event) {
                            element.label.options.display = true;
                            return true;
                        },
                        leave({element}, event) {
                            element.label.options.display = false;
                            return true;
                        }
                    }
                }
            },
        },
    };

    const data = {
        labels:["Plot",],
        datasets:datasets
    }
    return <>
        {/* <br></br><br></br>
        <div className="pill-header-wrap">
            <div className="pill-header">Urban Greening Budget</div>
            <div className="desc">How the primary design selected performs compared to the masterplan nature budget requirement.</div>
        </div>
        <div className="plot-budget-wrapper">
            <div className="plot-comparison-chart">
                <Bar options={options} data={data}/>
            </div>
        </div> */}
        <div className="results-pill">
            <div className="results-pill-title">
                Urban Greening Budget
            </div>
            <div className="desc">
                How the primary design selected performs compared to the masterplan nature budget requirement.
            </div>
            <div className='budget-chart-wrap'>
                <Bar options={options} data={data}/>
            </div>
        </div>
    </>
}