import React, { useState, useEffect, useContext } from 'react';
import { DashContext } from '../../utils/Context';
import "./AreaInput.css";

function AreaInput({ title, areaKey, initialValue, onChange, onCalculation }) {
  const [value, setValue] = useState(initialValue);

  const {
    baseAreaValues
} = useContext(DashContext);

  // Update local state when initialValue changes
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, baseAreaValues]);

  const handleInputChange = (e) => {
    setValue(e.target.value); 
    onChange(areaKey, e.target.value); 
    onCalculation(areaKey, e.target.value);
  };

  return (
    <div className="individual-input-wrap">
      <div className="input-title">{title}</div>
      <input
        className="input-field"
        type="number"
        value={value}
        onChange={handleInputChange}
      ></input>
    </div>
  );
}

export default AreaInput;
