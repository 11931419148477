import React, { useEffect} from 'react';
import Header from '../components/Header/Header';


function About() {

    useEffect(() => {
    }, []);
    
    return (
        <>
            <Header />
            <div className="background">
                <div className="wrapper">
                    <div className='about'>
                        <div className='title'>About Biome</div>
                        <div className='subtitle'>Introduction</div>
                        <div className='main-text about-text'>
                        Value is widely considered to be the worth or usefulness of a thing. In everyday life, 
                        both consciously and subconsciously, we use and interact with nature and so benefit from 
                        its value. To most people, the value of nature is felt, seen and experienced but rarely quantified.
                        <br></br><br></br>
                        Calculating nature's value in monetary or non-monetary terms is a challenge due to nature 
                        being unpredictable and changeable according to external factors (light, temperature, precipitation). 
                        Its benefits to people and society are vast and without it we simply wouldn't exist. The complexity 
                        in determining its value has ironically led to its devaluation by businesses, governments, and people. 
                        This has ultimately led to species and habitats entering a freefall and the start of the Earth's sixth 
                        mass extinction event, commonly being referred to as a biodiversity emergency.
                        <br></br><br></br>
                        To stay competitive, developers have an opportunity to utilise nature's inherent value so that the 
                        investments they make in creating nature generate both monetary and non-monetary value. To enable our 
                        clients to value nature, we have created Biome which uses an evidence base composed of a meta-study of 
                        scientific papers as well as the findings from engagement with industry thought leaders and academics. 
                        This evidence base has been used to build value profiles for different nature-based solution types. 
                        These profiles then enable landscapes & developments to be appraised by considering how they contribute 
                        to the attainment of a development's sustainability targets, business priorities and the wider value 
                        being created. The key outcome is an ability to rapidly optioneer, influence and support decisions 
                        around landscaping design based on the desired attributes being sought.

                        </div>
                        <div className='subtitle'>Usage</div>
                        <div className='main-text'>
                            Please note that configurations and calculations made with the tool are not saved unless logged in as an admin.
                        </div>
                        <div className='subtitle'>Sources</div>
                        <div className='main-text'>
                            <div>
                                Council, D.P., (2013) Technical support document: technical update of the social cost of carbon for regulatory impact analysis-under executive order 12866. Environmental Protection Agency.
                            </div>
                            <br></br>
                            <div>
                                eftec, WSP, ABPmer (2021) Biodiversity net gain: Market analysis. Accessed: 09/08/2023. Available <a href='https://randd.defra.gov.uk/ProjectDetails?ProjectID=20608'>here</a>
                            </div>
                            <br></br>
                            <div>
                                HM Treasury (2022) The Green Book. Accessed: 09/08/2023. Available <a href='https://www.gov.uk/government/publications/the-green-book-appraisal-and-evaluation-in-central-governent/the-green-book-2020#a1-non-market-valuation-and-unmonetisable-values'>here</a>
                            </div>
                            <br></br>
                            <div>
                                Mayor of London (2021) London Plan Guidance. Urban Greening Factor. Accessed: 09/08/2023. Available <a href='https://www.london.gov.uk/sites/default/files/2023-02/London%20Plan%20Guidance%20-%20Urban%20Greening%20Factor.pdf'>here</a>
                            </div>
                            <br></br>
                            <div>
                                Natural England (2023) The Biodiversity Metric 4.0. Accessed: 09/08/2023. Available <a href='https://publications.naturalengland.org.uk/publication/6049804846366720'>here</a>
                            </div>
                            <br></br>
                            <div>
                                The Gold Standard Foundation (2023) Offset your emissions. Accessed: 09/08/2023. Available <a href='https://marketplace.goldstandard.org/collections/projects'>here</a>
                            </div>
                            <br></br>
                            <div>
                                The IGNITION Project (2020) Nature-based solutions to the climate emergency. The benefits to business and society. Accessed: 14/12/2021. Available <a href='https://ukgbc.s3.eu-west-2.amazonaws.com/wp-content/uploads/2020/08/05144641/Nature-based-solutions-to-the-climate-emergency.pdf'>here</a>
                            </div>
                            <br></br>
                            <div>
                                UKGBC (2021) Renewable Energy Procurement & Carbon Offsetting. Accessed: 09/08/2023. Available <a href='https://www.ukgbc.org/wp-content/uploads/2021/03/Renewable-Energy-Procurement-Carbon-Offsetting-Guidance-for-Net-Zero-Carbon-Buildings.pdf'>here</a>
                            </div>
                            <br></br>
                            <div>
                                Woodland Carbon Code (2021) WCC Carbon Calculation Guidance V2.4 March 2021. Accessed: 09/08/2023. Available <a href='https://woodlandcarboncode.org.uk/standard-and-guidance/3-carbon-sequestration/3-3-project-carbon-sequestration'>here</a>
                            </div>
                        </div>
                        <div className='subtitle'>Authors</div>
                        <div className='main-text'>
                            Robert Winch, Jon Gregg, Ryan de Mello, Callum Mooney, Charlotte Knowles, Anna Fredlander and Greg Jones.
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    );
  }
  
  export default About;
  