
/**
 * Description placeholder
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @type {{ treesOverTwoThirds: { trees: number; }; treesUnderTwoThirds: { trees: number; }; extensiveGreenRoof: { accessibleMeetsGRO: number; nonaccessibleMeetsGRO: number; accessibleDoesntMeetGRO: number; nonaccessibleDoesntMeetGRO: number; }; intensiveGreeRoof: { ...; }; openGreenSpace: { ...; }; SuDS: { ...; }; greenwall:...}
 */
const urbanGreeningFactors={
    "treesOverTwoThirds":{
        "trees":0.8
    },
    "treesUnderTwoThirds":{
        "trees":0.6
    },
    "extensiveGreenRoof":{
        "accessibleMeetsGRO":0.7,
        "nonaccessibleMeetsGRO":0.7,
        "accessibleDoesntMeetGRO":0.3,
        "nonaccessibleDoesntMeetGRO":0.3
    },
    "intensiveGreeRoof":{
        "nonaccessGreenRoof":0.8,
        "accessibleGreenRoof":0.8
    },
    "openGreenSpace":{
        "semiNaturalVegetation":1,
        "flowerRichPlanting":0.7,
        "hedges":0.6,
        "groundCover":0.5,
        "amenityGrassland":0.4
    },
    "SuDS":{
        "rainGardens":0.7,
        "waterFeature":0.2,
        "wetlandOpenWater":1,
        "permeablePaving":0.1
    },
    "greenwall":{
        "greenwall":0.6
    }
}

/**
 * Conversion of landscape types from UGF to equivalent BNG
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @type {{ treesOverTwoThirds: { trees: string; }; treesUnderTwoThirds: { trees: string; }; extensiveGreenRoof: { accessibleMeetsGRO: string; nonaccessibleMeetsGRO: string; accessibleDoesntMeetGRO: string; nonaccessibleDoesntMeetGRO: string; }; intensiveGreeRoof: { ...; }; openGreenSpace: { ...; }; SuDS: { ...; }; greenwall:...}
 */
const ugf2bng = {
    "treesOverTwoThirds":{
        "trees":"Other woodland; mixed",
    },
    "treesUnderTwoThirds":{
        "trees":"Other woodland; mixed",
    },
    "extensiveGreenRoof":{
        "accessibleMeetsGRO":"Other green roof",
        "nonaccessibleMeetsGRO":"Other green roof",
        "accessibleDoesntMeetGRO":"Other green roof",
        "nonaccessibleDoesntMeetGRO":"Other green roof"
    },
    "intensiveGreeRoof":{
        "nonaccessGreenRoof":"Intensive green roof",
        "accessibleGreenRoof":"Intensive green roof"
    },
    "openGreenSpace":{
        "semiNaturalVegetation":"Open mosaic habitats on previously developed land",
        "flowerRichPlanting":"Open mosaic habitats on previously developed land",
        "hedges":"Zero",
        "groundCover":"Ground level planters",
        "amenityGrassland":"Unvegetated garden"
    },
    "SuDS":{
        "rainGardens":"Rain garden",
        "waterFeature":"Sustainable drainage system",
        "wetlandOpenWater":"Sustainable drainage system",
        "permeablePaving":"Zero"
    },
    "greenwall":{
        "greenwall":"Facade-bound green wall"
    }
}

/**
 * DEFRA's scoring system for BNG landscape types
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @type {{ Allotments: { distinctiveness: number; condition: number; strategicSignificance: number; timeToTarget: number; difficulty: number; }; "Artificial unvegetated, unsealed surface": { distinctiveness: number; condition: number; strategicSignificance: number; timeToTarget: number; difficulty: number; }; ... 19 more ......}
 */
export const DefraBNGMetrics = {
    "Allotments":{
        distinctiveness:2,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.965,
        difficulty:1
    },
    "Artificial unvegetated, unsealed surface":{
        distinctiveness:0,
        condition:0,
        strategicSignificance:1.1,
        timeToTarget:1,
        difficulty:1
    },
    "Bioswale":{
        distinctiveness:2,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.965,
        difficulty:0.67
    },
    "Intensive green roof":{
        distinctiveness:2,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.898632125,
        difficulty:1
    },
    "Built linear features":{
        distinctiveness:0,
        condition:0,
        strategicSignificance:1.1,
        timeToTarget:1,
        difficulty:1
    },
    "Cemeteries and churchyards":{
        distinctiveness:4,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.5860163055,
        difficulty:0.67
    },
    "Developed land; sealed surface":{
        distinctiveness:0,
        condition:0,
        strategicSignificance:1.1,
        timeToTarget:1,
        difficulty:0.67
    },
    "Other green roof":{
        distinctiveness:2,
        condition:1,
        strategicSignificance:1.1,
        timeToTarget:0.965,
        difficulty:1
    },
    "Facade-bound green wall":{
        distinctiveness:2,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.898632125,
        difficulty:0.67
    },
    "Ground based green wall":{
        distinctiveness:2,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.898632125,
        difficulty:0.67
    },
    "Ground level planters":{
        distinctiveness:2,
        condition:1,
        strategicSignificance:1.1,
        timeToTarget:0.965,
        difficulty:1
    },
    "Biodiverse green roof":{
        distinctiveness:4,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.8368287006,
        difficulty:0.67
    },
    "Introduced shrub":{
        distinctiveness:2,
        condition:1,
        strategicSignificance:1.1,
        timeToTarget:0.965,
        difficulty:1
    },
    "Open mosaic habitats on previously developed land":{
        distinctiveness:6,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.8671800006,
        difficulty:0.67
    },
    "Rain garden":{
        distinctiveness:2,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.898632125,
        difficulty:1
    },
    "Actively worked sand pit quarry or open cast mine":{
        distinctiveness:2,
        condition:1,
        strategicSignificance:1.1,
        timeToTarget:0.965,
        difficulty:0.67
    },
    "Sustainable drainage system":{
        distinctiveness:2,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.898632125,
        difficulty:0.67
    },
    "Unvegetated garden":{
        distinctiveness:0,
        condition:0,
        strategicSignificance:1.1,
        timeToTarget:1,
        difficulty:1
    },
    "Vacant or derelict land":{
        distinctiveness:2,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.898632125,
        difficulty:1
    },
    "Bare ground":{
        distinctiveness:2,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.898632125,
        difficulty:1
    },
    "Vegetated garden":{
        distinctiveness:2,
        condition:1,
        strategicSignificance:1.1,
        timeToTarget:0.965,
        difficulty:1
    },
    "Other woodland; mixed":{
        distinctiveness:4,
        condition:2,
        strategicSignificance:1.1,
        timeToTarget:0.3434151104,
        difficulty:1
    },
    "Zero":{
        distinctiveness:0,
        condition:0,
        strategicSignificance:0,
        timeToTarget:0,
        difficulty:0
    }

}

/**
 * Sequestration rates for UGF main landscape types
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @type {{ extensiveGreenRoof: number; intensiveGreeRoof: number; openGreenSpace: number; greenwall: number; SuDS: number; }}
 */
const SequestrationRates = {
    extensiveGreenRoof:0.00128,
    intensiveGreeRoof:0.00128,
    openGreenSpace:0.0002,
    greenwall:0.00068,
    SuDS:0.00027
}

/**
 * The assumed cumulative sequestration rate for a tree over 100 years by 5 year increment
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @type {{ 5: number; 10: number; 15: number; 20: number; 25: number; 30: number; 35: number; 40: number; 45: number; 50: number; 55: number; 60: number; 65: number; 70: number; 75: number; 80: number; 85: number; 90: number; 95: number; 100: number; }}
 */
const TreeRates = {
    5:0.0003,
    10:0.0016,
    15:0.0052,
    20:0.0118,
    25:0.0182,
    30:0.0242,
    35:0.0306,
    40:0.035,
    45:0.0381,
    50:0.0406,
    55:0.0424,
    60:0.0438,
    65:0.0451,
    70:0.0462,
    75:0.0472,
    80:0.0482,
    85:0.0491,
    90:0.05,
    95:0.051,
    100:0.0518
}

/**
 * For a given period of time, will calculate the total sequestered amount based on UGF rates and return an object for total sequestered carbon over that period
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @export
 * @param {*} num Length of time to calculate sequestration over
 * @returns {{ trees: any; }} Object of UGF landscape types and sequestered carbon
 */
export function totalSeqOverYear(num){
    let rates = {}
    for(let key in SequestrationRates){
        rates[key]=SequestrationRates[key]*num
    }
    for(let key in TreeRates){
        if(key<=num){
            rates["trees"] = TreeRates[key]
        }
    }
    return rates
}

/**
 * Assumptions for rainwater runoff rates for UGF landscape types
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @type {{ trees: number; extensiveGreenRoof: number; intensiveGreeRoof: number; openGreenSpace: number; greenwall: number; SuDS: number; }}
 */
const rainwaterRunoff = {
    trees:0.43,
    extensiveGreenRoof:0.62,
    intensiveGreeRoof:0.73,
    openGreenSpace:0.84,
    greenwall:0.75,
    SuDS:0.69
}

/**
 * Benefits across key metrics for UGF landscape types
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @type {{ trees: { temp: number; noise: number; airQuality: number; runoffRetention: number; waterQuality: number; propertyValue: number; }; extensiveGreenRoof: { temp: number; noise: number; airQuality: number; runoffRetention: number; waterQuality: number; propertyValue: number; }; intensiveGreeRoof: { ...; }; openGreenSp...}
 */
const ugfBenefits = {
    trees:{
        temp:3,
        noise:4,
        airQuality:0.3125,
        runoffRetention:0.43,
        waterQuality:0.7,
        propertyValue:0.047
    },
    extensiveGreenRoof:{
        temp:0.8,
        noise:11,
        airQuality:0.19,
        runoffRetention:0.62,
        waterQuality:0.72,
        propertyValue:0.069
    },
    intensiveGreeRoof:{
        temp:1.1,
        noise:46,
        airQuality:0.19,
        runoffRetention:0.73,
        waterQuality:0.72,
        propertyValue:0.069
    },
    openGreenSpace:{
        temp:2.7,
        noise:4,
        airQuality:0.0585,
        runoffRetention:0.84,
        waterQuality:0.48,
        propertyValue:0.095
    },
    greenwall:{
        temp:2,
        noise:9.8,
        airQuality:0.3125,
        runoffRetention:0.75,
        waterQuality:0.65,
        propertyValue:0.025
    },
    SuDS:{
        temp:0,
        noise:0,
        airQuality:0,
        runoffRetention:0.69,
        waterQuality:0.62,
        propertyValue:0.009
    }

}

/**
 * Converts beenfit codes to arrays for chartjs to display with wrapped text on multiple lines
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @type {{ temp: {}; noise: {}; airQuality: {}; runoffRetention: {}; waterQuality: {}; propertyValue: {}; }}
 */
export const benefitNames = {
    temp:["Temperature","Reduction"],
    noise:["Noise","Reduction"],
    airQuality:["Air Quality","Improvement"],
    runoffRetention:["Rainwater Runoff","Retention"],
    waterQuality:["Water","Quality"],
    propertyValue:["Property","Value"]
}


// const habitats = {
//     moss:{
//         trees:0.02,
//         extensiveGreenRoof:0.02,
//         intensiveGreeRoof:0.02,
//         openGreenSpace:0.02,
//         greenwall:0.02,
//         SuDS:0.02
//     },
//     smallPlants:{
//         trees:0.1,
//         extensiveGreenRoof:0.04,
//         intensiveGreeRoof:0.04,
//         openGreenSpace:0.1,
//         greenwall:0.05,
//         SuDS:0.04
//     },
//     perennials:{
//         trees:0.2,
//         extensiveGreenRoof:0.06,
//         intensiveGreeRoof:0.06,
//         openGreenSpace:0.15,
//         greenwall:0.06,
//         SuDS:0.06
//     },
//     treesAndShrubs:{
//         trees:0.4,
//         extensiveGreenRoof:0.08,
//         intensiveGreeRoof:0.08,
//         openGreenSpace:0.18,
//         greenwall:0.08,
//         SuDS:0.08
//     },
//     climaticForest:{
//         trees:0.48,
//         extensiveGreenRoof:0.11,
//         intensiveGreeRoof:0.11,
//         openGreenSpace:0.20,
//         greenwall:0.11,
//         SuDS:0.10
//     }
// }


/**
 * Rounds a number to 2DP
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @export
 * @param {*} num Input decimal number to be changed
 * @returns {Number} Output number to 2 decimal places
 */
export function round2DP(num){
    return Number(num.toFixed(2));
}

/**
 * Adds commas every 3rd digit to long numbers (every 000)
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @export
 * @param {*} x Multi digit number
 * @returns {String}
 */
export function commas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Processes the input and entitiy information to calculate various metrics on Tree Canopy Cover
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @export
 * @param {{*}} input Object of input values
 * @param {{}} area Object of area values
 * @param {{}} ref Project level data to check assumed tree sizes
 * @param {Number} plot Entity level data to get total area and existing trees
 * @returns {[Number,Number,Number,Number]} Array of [Total tree area, % tree cover of entity area, Sum of tree area with big pits, Sum of tree area with small pits]
 */

export function TreeCanopy (input, area, ref, plot){
    let old = plot?.setup?.existingTreeCanopy
    let sUnder = input?.treesUnderTwoThirds.smallTrees*ref?.config?.trees.smallTreeCanopySize
    let mUnder = input?.treesUnderTwoThirds.mediumTrees*ref?.config?.trees.mediumTreeCanopySize
    let lUnder = input?.treesUnderTwoThirds.largeTrees*ref?.config?.trees.largeTreeCanopySize

    let sOver = input?.treesOverTwoThirds.smallTrees*ref?.config?.trees.smallTreeCanopySize
    let mOver = input?.treesOverTwoThirds.mediumTrees*ref?.config?.trees.mediumTreeCanopySize
    let lOver = input?.treesOverTwoThirds.largeTrees*ref?.config?.trees.largeTreeCanopySize

    let sumUnder = sUnder+mUnder+lUnder

    let sumOver = sOver+mOver+lOver

    let totalArea = old + sumUnder + sumOver

    let percentArea = Math.round(totalArea/area.totalArea*100)
    return [totalArea, percentArea, sumUnder, sumOver]
}

/**
 * Sums input values to get total new trees
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @export
 * @param {{}} input Object of input values
 * @returns {Number} Total new trees planted
 */
export function TreesPlanted (input){
    let small = (input.treesOverTwoThirds.smallTrees + input.treesUnderTwoThirds.smallTrees)
    let medium = (input.treesOverTwoThirds.mediumTrees + input.treesUnderTwoThirds.mediumTrees)
    let large = (input.treesOverTwoThirds.largeTrees + input.treesUnderTwoThirds.largeTrees)
    let total = small + medium + large
    return total
}

/**
 * Calculates the % area of accessible planting
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @export
 * @param {{}} input Object of input values
 * @param {{}} area Object of area values
 * @param {Number} totalTrees Total number of new trees planted
 * @returns {Number} A percent of the public realm area that is landscaped 
 */
export function AccessiblePlanting (input, area, totalTrees){
    // Takes in the slider inputs, the total number of trees and the plot entity

    let publicRealm = area.publicRealm
    // Grabs the public realm area from the entity

    let total = input.openGreenSpace.semiNaturalVegetation +
    input.SuDS.wetlandOpenWater +
    input.intensiveGreeRoof.accessibleGreenRoof+
    totalTrees + 
    input.extensiveGreenRoof.accessibleMeetsGRO+
    input.openGreenSpace.flowerRichPlanting +
    input.openGreenSpace.hedges +
    input.SuDS.rainGardens +
    input.openGreenSpace.groundCover +
    input.openGreenSpace.amenityGrassland+
    input.greenwall.greenwall
    // Sums up all the specified areas to calculate m2 of selected "accessible" landscape types
    
    if(publicRealm === 0){
    // Checks the size of the public realm for the selected plot. 

        return 0
        // If it's 0, the calc will return 0 to avoid trying to divide by 0 and breaking the calc.

    } else {
        return Math.round(total/publicRealm*100)
        // Divides the area by the public realm area, multiplied by 100 and rounded to nearest number
        // to present as a whole percentage e.g. 91%

    }
}

/**
 * Calculated Urgan Greening Factor - loops through factors and multiplies them by inputs
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @export
 * @param {{}} input Object of input values
 * @param {{}} area Object of area values
 * @param {Number} treeUnder Tree area with small pits
 * @param {Number} treeOver Tree area with big pits
 * @returns {Number} A UGF ration to 2dp
 */
export function UrbanGreening (input, area, treeUnder,treeOver){
    let results =[]
    for(let key in urbanGreeningFactors){
        for(let subkey in urbanGreeningFactors[key]){
            
            if(key==="treesOverTwoThirds"){
                let val = treeOver * urbanGreeningFactors[key][subkey]
                results.push(val)
            } else if(key==="treesUnderTwoThirds"){
                let val = treeUnder * urbanGreeningFactors[key][subkey]
                results.push(val)
            } else {
                let val = input[key][subkey] * urbanGreeningFactors[key][subkey]
                results.push(val)
            }
            
        }
    }
    
    let ratio = Number(((results.reduce((a, b) => a + b, 0))/area.totalArea).toFixed(3));
    
    return ratio
}

export function UrbanGreeningBreakdown (input,area, treeUnder, treeOver){
    let results = {}
    for(let key in urbanGreeningFactors){
        let array = []
        for(let subkey in urbanGreeningFactors[key]){
            if(key==="treesOverTwoThirds"){
                let val = treeOver * urbanGreeningFactors[key][subkey]
                array.push(val)
            } else if(key==="treesUnderTwoThirds"){
                let val = treeUnder * urbanGreeningFactors[key][subkey]
                array.push(val)
            } else {
                let val = input[key][subkey] * urbanGreeningFactors[key][subkey]
                array.push(val)
            }
        }
        
        let ratio = Number(((array.reduce((a, b) => a + b, 0))/area.totalArea).toFixed(3));
        // let ratio = Number(((array.reduce((a, b) => a + b, 0))/area.totalArea));
        results[key]=ratio
    }
    results["trees"] = results["treesOverTwoThirds"] + results["treesUnderTwoThirds"]
    delete results["treesOverTwoThirds"]
    delete results["treesUnderTwoThirds"]
    return results
}

/**
 * Calculates BNG as either a % change or the actual new units from landscaping
 * @date 8/21/2023 - 9:28:44 AM
 *
 * @export
 * @param {{}} input Object of input values
 * @param {Number} treeUnder Tree area with small pits
 * @param {Number} treeOver Tree area with large pits
 * @param {{}} plot Entity object
 * @param {Boolean} change If true, returns the % change, otherwise returns new units
 * @returns {Number} Either a % change or value of new units
 */
export function BiodiversityNetGain(input,treeUnder,treeOver,plot, change){
    // Takes in the inputs sliders, calculated areas for two tree types, the plot entity 
    // and an option to either show % change over baseline or the actual biodiversity.
    
    let existingUnits = plot?.setup.existingBiodiversityUnits
    // Grabs the existing biodiversity units from the plot entity.

    let results = []
    let area
    let m2ha = 0.0001
    // Assigns some empty variables, along with the conversion of m2 to ha.

    for(let key in ugf2bng){
    // Loops through each UGF type e.g. Open Green Space, SuDs.

        for(let subkey in ugf2bng[key]){
        // Loops through each UGF subcategory within the main category e.g. small, medium and large trees
        // within trees with planting areas over 2/3s volume.

            let bngType = ugf2bng[key][subkey]
            // Looks up the equivalent BNG type based on the conversion of UGF to BNG.

            if(key==="treesOverTwoThirds"){
                area = (treeOver+plot?.setup.existingTreeCanopy)*m2ha
            } else if(key==="treesUnderTwoThirds"){
                area = treeUnder*m2ha
            } else {
                area = input[key][subkey]*m2ha
            }
            // This populates a list with the areas of the different landscape types converted to ha for the BNG calc
            // It checks the main UGF category first as the calculation is slightly different if the category is trees,
            // as these values are passed directly in, otherwise it grabs the input slider values.

            let rates = DefraBNGMetrics[bngType]
            // Grabs the DEFRA rates (distinctiveness, condition, strategic significance, time to target, difficulty)
            // for different BNG types. These were taken from the excel tool so further documetation is available there.

            for(let factor in rates){
                area=area*rates[factor]
            }
            // Loops through the DEFRA rates, and multiplies the BNG area by the 
            // relevant factors to get the resultant biodiversity units.

            results.push(parseFloat(area))
            // Adds the units to a list, and then loops onto the next subcategory.
        }
    }

    let newUnits = parseFloat(results.reduce((a, b) => a + b, 0))
    // Sums up all the values in the results list to calculate the total units from all the areas

    if(change){
    // Checks if the change flag is true of false. This allows for this calc to be used in different places in the tool
    // by changing this flag depending on the context of where it's called:
    //  - If true, it will calculate the change in units as a percentage compared to the baseline units
    //  - If false, it will simply return the absolute units

        if(existingUnits===0){
        // Checks if there are no existing biodiversity units. Without this check, the calculation below attempts to divide by zero,
        // thus breaking the calc.

            return 100
            // In the event that there are no existing units, it will simply return 100% as any biodiversity added
            // will be a 100% capped improvement.

        } else {
            return Math.round(((newUnits-existingUnits)/existingUnits*100))
            // If there are some existing units, then subtracts the existing units from the new units created, divided by existing units
            // to calculate the change.

        }
    } else {
        return newUnits
        // If the change flag is false, it the newly created units are outputted as an absolute value e.g. 9.17.
    }

}

/**
 * Takes the input object and reduces the suboptions down to calculate the total areas for the main UGF landscape types
 * @date 8/21/2023 - 9:28:43 AM
 *
 * @export
 * @param {*} input Input object
 * @param {*} treesUnder Tree area with small pits
 * @param {*} treesOver Tree area with large pits
 * @returns {{ trees: any; extensiveGreenRoof: any; intensiveGreeRoof: any; openGreenSpace: any; SuDS: any; greenwall: any; }} Object of areas for UGF landscape types
 */
export function LandscapeAreas(input, treesUnder, treesOver){
    const trees=treesUnder+treesOver
    
    if(input){
        const sumVals = obj => Object.values(obj).reduce((a, b) => a + b, 0);
        const extGreenRoof = sumVals(input?.extensiveGreenRoof)
        const intGreenRoof = sumVals(input?.intensiveGreeRoof)
        const GreenSpace = sumVals(input?.openGreenSpace)
        const SuDS = sumVals(input?.SuDS)
        const greenwall = input?.greenwall.greenwall

        const areas = {
            trees:trees,
            extensiveGreenRoof:extGreenRoof,
            intensiveGreeRoof:intGreenRoof,
            openGreenSpace:GreenSpace,
            SuDS:SuDS,
            greenwall:greenwall
        }
        return areas
    } else {
        const areas = {
            trees:trees,
            extensiveGreenRoof:0,
            intensiveGreeRoof:0,
            openGreenSpace:0,
            SuDS:0,
            greenwall:0
        }
        return areas
    }
}

/**
 * Calculates cumulative inflation for a value over a period of time
 * @date 8/21/2023 - 9:28:43 AM
 *
 * @param {Number} startYear Initial year for the calculation
 * @param {Number} years Period of time to calculate over
 * @param {Number} baseVal Starting value to apply inflation too
 * @param {Number} rate Rate of inflation as a decimal e.g. 1.03 is 3%
 * @returns {[{year:value}]} Object array with year:value pairs 
 */
export const InflationaryRate = (startYear, years, baseVal, rate) => {
    let array = [{year:startYear, value:baseVal}]
    for(let i=1; i<=years; i++){
        let thisVal = array[i-1].value*rate
        let thisYear = array[i-1].year+1
        let thisObj = {year:thisYear, value:thisVal}
        array.push(thisObj)
    }
    return array
}

/**
 * Calculates sequestration
 * @date 8/21/2023 - 9:28:43 AM
 *
 * @param {Number} years Period of years to calculate over
 * @param {Number} area Area to multiply the rate by
 * @param {Number} rate Rate of sequestration per m2
 * @returns {[]} An array of the cumulative sequestered rate of time
 */
export const SequestrationCalc = (years, area, rate) => {
    let array = [area*rate]
    for(let i=1; i<=years; i++){
        let thisVal = (area*rate)+array[i-1]
        array.push(thisVal)
    }
    return array
}

/**
 * Description placeholder
 * @date 8/21/2023 - 9:28:43 AM
 *
 * @param {Number} years Period of years to calculate over
 * @param {Number} area Area to multiply the rate by
 * @returns {[]} An array of the cumulative sequestered rate of time, taking into account the time stpes of 5 years
 */
export const TreeSequestRate = (years, area) => {
    
    let rate = TreeRates[5]
    let array = [rate*area]

    for(let i=1; i<=years; i++){
        if(i % 5 === 0){
            rate = TreeRates[i+5]
        }
        let thisVal = (area*rate)
        array.push(thisVal)
    }
    return array
}

/**
 * Calculates the value to a developer over time throuh avoided carbon offsets. Starting rates are hardcoded as 15.45, 42,725 and 70 to provide a range of uncertainty and return different trajectories
 * @date 8/21/2023 - 9:28:43 AM
 *
 * @param {{}} input Object of input values
 * @param {Number} treesUnder Tree area with small pits
 * @param {Number} treesOver Tree area with large pits
 * @param {Number} years Period of years to calculate over
 * @param {Number} startYear Starting year for value calc
 * @param {Number} rate Rate of inflation to assume
 * @returns {{ years: {year:value}; high: []; medium: []; low: []; }} years provides an object with the year timespan as a key:value pair, where value can be ignored
 */
export const DeveloperValueCalc = (input, treesUnder, treesOver, years, startYear, rate) => {
    let high = InflationaryRate(startYear, years, 70, rate)
    let medium = InflationaryRate(startYear, years, 42.725, rate)
    let low = InflationaryRate(startYear, years, 15.45, rate)
    let areas = LandscapeAreas(input, treesUnder, treesOver)
    let totalSeq = new Array(years)
    totalSeq = totalSeq.fill(0)

    for(let key in areas){
        if(key==="trees"){
            let thisSeq = TreeSequestRate(years,areas[key])// eslint-disable-next-line
            let sum = thisSeq.map(function (num, idx) {
                return num + totalSeq[idx];
            });
            totalSeq = sum
        }else{
            let thisSeq = SequestrationCalc(years, areas[key], SequestrationRates[key])// eslint-disable-next-line
            let sum = thisSeq.map(function (num, idx) {
                return num + totalSeq[idx];
            });
            totalSeq = sum
        }
    }
    
    let highCost = high.map(function(num,idx){
        return Math.ceil(num.value * totalSeq[idx]/10)*10
    })

    let mediumCost = medium.map(function(num,idx){
        return Math.ceil(num.value * totalSeq[idx]/10)*10
    })

    let lowCost = low.map(function(num,idx){
        return Math.ceil(num.value * totalSeq[idx]/10)*10
    })

    return {
        years:high,
        high:highCost,
        medium:mediumCost,
        low:lowCost
    }
}

/**
 * Social cost of carbon estimates (£/tCO2) with multiple trajectories https://www.epa.gov/sites/default/files/2016-12/documents/sc_co2_tsd_august_2016.pdf
 * @date 8/21/2023 - 9:28:43 AM
 *
 * @type {{ 2020: { low: number; medium: number; high: number; }; 2025: { low: number; medium: number; high: number; }; 2030: { low: number; medium: number; high: number; }; 2035: { low: number; medium: number; high: number; }; 2040: { ...; }; 2045: { ...; }; 2050: { ...; }; }}
 */
const SocialCost = {
    2020:{
        low:7.85,
        medium:44.18,
        high:80.5
    },
    2025:{
        low:9.16,
        medium:49.74,
        high:90.32
    },
    2030:{
        low:10.47,
        medium:54.98,
        high:99.48
    },
    2035:{
        low:11.78,
        medium:60.87,
        high:109.96
    },
    2040:{
        low:13.74,
        medium:66.76,
        high:119.77
    },
    2045:{
        low:15.05,
        medium:72,
        high:128.94
    },
    2050:{
        low:17.02,
        medium:77.89,
        high:138.75
    }
}

/**
 * Calculates value to society using base values and projecting forward.
 * @date 8/21/2023 - 9:28:43 AM
 *
 * @param {{}} input Object of input values
 * @param {Number} treesUnder Tree area with small pits
 * @param {Number} treesOver Tree area with large pits
 * @param {Number} years Period of years to calculate over
 * @param {Number} startYear Starting year for value calc
 * @returns {{ year: {year:value}; high: []; medium: []; low: []; }} years provides an object with the year timespan as a key:value pair, where value can be ignored
 */
export const SocietalValueCalc = (input, treesUnder, treesOver, years, startYear) =>{
    let areas = LandscapeAreas(input, treesUnder, treesOver)

    let totalSeq = new Array(years)
    totalSeq = totalSeq.fill(0)

    for(let key in areas){
        if(key==="trees"){
            let thisSeq = TreeSequestRate(years,areas[key])
            let sum = thisSeq.map(function (num, idx) {
                return num + totalSeq[idx];
            });
            totalSeq = sum
        }else{
            let thisSeq = SequestrationCalc(years, areas[key], SequestrationRates[key])
            let sum = thisSeq.map(function (num, idx) {
                return num + totalSeq[idx];
            });
            totalSeq = sum
        }
    }

    let highCost = []
    let mediumCost = []
    let lowCost = []
    let yearArray = []
    let x = startYear
    let rate = SocialCost[2020]

    for(let i in totalSeq){
        if(x % 5 === 0 & x<2051){
            rate = SocialCost[x]
        }
        yearArray.push(x)
        highCost.push(Math.ceil(rate.high * totalSeq[i]/100)*100)
        mediumCost.push(Math.ceil(rate.medium * totalSeq[i]/100)*100)
        lowCost.push(Math.ceil(rate.low * totalSeq[i]/100)*100)

        x++
    }

    return{
        year:yearArray,
        high:highCost,
        medium:mediumCost,
        low:lowCost
    }

}

/**
 * Calculates % rainwater management for whole entity
 * @date 8/21/2023 - 9:28:43 AM
 *
 * @param {{}} input Object of input values
 * @param {{}} area Object of area values
 * @param {Number} treesUnder Tree area with small pits
 * @param {Number} treesOver Tree area with large pits
 * @param {{}} plot Entity obect
 * @returns {Number} Percentage for rainwater managed
 */
export const calcRainwaterManagement = (input, area, treesUnder, treesOver, plot) => {
    let areas = LandscapeAreas(input, treesUnder, treesOver)
    let array = []
    for(let key in areas){
        if(key==="trees"){
            let totalTrees = treesUnder + treesOver + plot.setup.existingTreeCanopy
            let runoff = rainwaterRunoff[key]*totalTrees
            let percent = runoff/area.totalArea
            array.push(percent)
        }else{
            let runoff = rainwaterRunoff[key]*areas[key]
            let percent = runoff/area.totalArea
            array.push(percent)
        }
    }
    let sum = Number(((array.reduce((a, b) => a + b, 0))*100).toFixed(0));
    return sum
}

/**
 * Calculates the scoring across the main benefit metrics for a given input
 * @date 8/21/2023 - 9:28:43 AM
 *
 * @param {{}} vals Object of input values
 * @param {Number} treesUnder Tree area with small pits
 * @param {Number} treesOver Tree area with large pits
 * @returns {{}} Object of benefits and their scoring
 */
export const benefitArray = (vals, treesUnder, treesOver) => {
    
    let areas = LandscapeAreas(vals, treesUnder, treesOver)
    let results = {}
    let sum = {}
    for(let key in ugfBenefits){
        let thisObj = {}
        for(let subkey in ugfBenefits[key]){
            let val = ugfBenefits[key][subkey]
            thisObj[subkey] = round2DP(areas[key]*val)
            if(sum[subkey]===undefined){
                sum[subkey]=thisObj[subkey]
            } else {
                sum[subkey] += thisObj[subkey]
            }
        }
        results[key] = thisObj
    }
    for(let key in sum){
        sum[key] = round2DP(sum[key])
    }
    return sum
}

/**
 * Calculates the threshold for the background of the radar chart, as well as the maxes
 * @date 8/21/2023 - 9:28:43 AM
 *
 * @returns {[{},{}]} Object for thresholds, Object for maxes
 */
export const generateRadarThresholds = () => {
    let maxes = {}
    for(let key in ugfBenefits){
        for(let subkey in ugfBenefits[key]){
            let val = ugfBenefits[key][subkey]
            if(maxes[subkey]===undefined){
                maxes[subkey]=val
            } else {
                if(val>maxes[subkey]){
                    maxes[subkey] = val
                }
            }
        }
    }
    let thresholds = {}
    for(let key in maxes){
        let val = maxes[key]/5
        let array = []
        for(let i = 1; i<6;i++){
            array.push(round2DP((i*val)/maxes[key]))
        }
        thresholds[key]=array
    }
    return [thresholds, maxes]
}