import Colourer from "../../utils/Colourer"
import './StatusPill.css'
import TargetChecker from "../../utils/TargetChecker"
import { DashContext } from "../../utils/Context"
import { useContext } from "react"
import { KPILibrary, renameFactors } from "../../utils/Basic"
import HelpWindow from "../HelpWindow/HelpWindow"


function StatusPill(props){
    const {
        thisPlot
    } = useContext(DashContext);

    if(!("kpi" in thisPlot)){
        return <></>
    }
    const thisKPI = thisPlot.kpi.find(o => o.name === props.kpi.name)
    let levelHit = TargetChecker(props.kpi.value, props.kpi.name, thisPlot)
    if(levelHit==="Max"){
        levelHit="Aspiration"
    }

    const checkMark = <span className="tick">✔</span>

    return (
        <div className="pill-container">
            {/* <div className="help-wrap">
                <HelpWindow title={props.kpi.name}/>
            </div> */}
            <div className={props.showTargets?'pill':'pill closed'}>
                <div className="text">
                    <p className="pill-title">{renameFactors(props.kpi.name)}</p>
                    <p className="pill-desc">{KPILibrary[thisKPI.name].desc}</p>
                </div>
                <div className="indicator" style={{backgroundColor:Colourer(props.kpi.value, props.kpi.name, thisPlot)}}>
                    {props.kpi.value}
                </div>
                <div className="pill-targets">
                    {
                        thisKPI.targets.map((data)=>(
                            data.max?"":
                            <div className="target-row" key={data.name}>
                                <div className="check-mark">
                                    {levelHit===data.name?checkMark:''}
                                </div>
                                
                                <div className="target-name">{levelHit===data.name?'':''}{data.name}</div>
                                <div className="target-level">{data.value}</div>
                                
                            </div>
                        ))
                    }
                </div>
                
            </div>
        </div>
    )
}

export default StatusPill