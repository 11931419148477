import { useEffect, useState, useContext } from 'react';
import './Slider.css'
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { DashContext } from '../../utils/Context';

function Slider (props){
    const[thisValue, setValue] = useState(props.default)
    const {baseValues} = useContext(DashContext)

    const handleChange = (value) => {
        // console.log(value)
        if(value===""){
            setValue(0)
        } else {
            setValue(value)
            props.func(props.id[0], props.id[1], Number(value))
        }
    }

    useEffect(() => { setValue(props.default) }, [props.default,baseValues]);

    return (
        <div className="slider-wrapper">
            <p className="slider-title">{props.title}</p>
            <div className="slider">
                <RangeSlider 
                    max={props.max}
                    min={props.min}
                    className="single-thumb" 
                    defaultValue={[0, props.default]}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                    value={[0,thisValue]}
                    onInput={
                        v => {
                            setValue(v[1])
                            props.func(props.id[0], props.id[1], v[1])
                        }
                    }
                    onThumbDragEnd={
                        props.endFunc
                    }
                    step={props.step}
                />
            </div>
            <input type="text" className="value" value={thisValue} id={props.id} onChange={e => handleChange(e.target.value)}></input>
            
        </div>
    )
}

export default Slider