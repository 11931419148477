/**
 * Creates a date timestamp
 * @date 8/21/2023 - 9:25:37 AM
 *
 * @export
 * @returns {*} Current date
 */
export function createDate(){
    let date = new Date().toLocaleDateString()
    return date
}

/**
 * Takes a dictionary of the KPIs and values, and converts it to object array of individual KPIs and values
 * @date 8/21/2023 - 9:25:37 AM
 *
 * @export
 * @param {*} data Single object of KPIs and values
 * @returns {{}} Array of seperated KPI objects
 */
export function prepKPI(data){
    let newForm = []
    for(let key in data){
        let thisObj = {name:key, value:data[key]}
        newForm.push(thisObj)
    }
    return newForm
}

export const KPILibrary = {
    "Urban Greening":{
        "fullName":"Urban Greening Factor",
        "units":"ratio",
        "desc":"Ratio of urban greening across site"
    },
    "Rainwater":{
        "fullName":"Rainwater management",
        "units":"%",
        "desc":"% of rainwater to be managed through the landscape"
    },
    "Biodiversity Change":{
        "fullName":"Biodiversity Net Gain",
        "units":"%",
        "desc":"% change in biodiversity"
    },
    "Tree Cover":{
        "fullName":"Tree canopy cover",
        "units":"%",
        "desc":"Total number of new trees planted"
    },
    "Greenspace":{
        "fullName":"Access to nature",
        "units":"%",
        "desc":"% of accessible site with stimulating planting"
    },
    "Trees Planted":{
        "fullName":"Trees planted",
        "units":"count",
        "desc":"% of site footprint with tree canopy cover"
    }
}

export function renameFactors(name){
    if(name in KPILibrary){
        return KPILibrary[name]["fullName"]
    } else {
        return name
    }
}

export function addUnits(name){
    if(name in KPILibrary){
        return KPILibrary[name]["units"]
    } else {
        return "tbc"
    }
}

export function round1000(num){
    return Math.round(num/1000)*1000
}