import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { useContext, useEffect } from 'react';
import { AuthContext, DashContext } from '../../utils/Context';
import { Bar } from 'react-chartjs-2';
import { addUnits, renameFactors } from '../../utils/Basic';
import annotationPlugin from 'chartjs-plugin-annotation';
import './KPIChart.css'
import Colourer from '../../utils/Colourer';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    annotationPlugin,
    Legend
);
ChartJS.defaults.font.family="Lato"



/**
 * Description placeholder
 * @date 8/21/2023 - 12:25:35 PM
 *
 * @param {number} number
 * @returns {number}
 */
function Rounder(number){
    if(number<2){
        return Number(number.toFixed(1))
    } else if(number<300){
        return Number(Math.ceil(number / 10) * 10);
    } else {
        return Number(Math.ceil(number / 100) * 100);
    }
}


  
/**
 * Generates a chartjs stacked bar chart with annotated indicator for KPIs
 * @date 8/21/2023 - 12:25:35 PM
 *
 * @param {{}} kpis KPI from entity array of KPIs
 * @param {{}} plotData Object of this entity data
 * @param {{}} inputValues Object of input values
 * @param {{}} projectData Overall project data
 * @param {{}} baselinePlot Default plot for reference on graph
 * @returns {*} Individual bar chart for a kpi
 */
function KPIChart(kpis, plotData, inputValues, projectData,baselinePlot) {
    // console.log(kpis["name"])
    // console.log(inputValues[kpis.name])
    let thisData = {
        labels:[''],
        datasets:[]
    }

    let suggestedMax
    let suggestedMin = 0
    let baselinePerformance = 0
    baselinePerformance = (baselinePlot.performance.find(o => o.name === kpis.name)).value
    let calcaultedPerformance = 0

    calcaultedPerformance = (inputValues[kpis.name])

    for(let level in kpis.targets){
        let val
        suggestedMax = kpis.targets[level]?.value
        if(level === "0"){
            val = kpis.targets[level]?.value
        } else {
            val = kpis.targets[level]?.value - kpis.targets[(Number(level)-1)]?.value 
        }

        let thisObj = {
            label:projectData.config.levels[level].name,
            data: [val],
            backgroundColor: projectData.config.levels[level].color,
        }

        thisData.datasets.push(thisObj)
    }

    if(calcaultedPerformance>suggestedMax){
        let oldMax = suggestedMax
        suggestedMax = Rounder(calcaultedPerformance*1.1)
        let oldVal = thisData.datasets[thisData.datasets.length -1].data[0]
        let newVal = suggestedMax-oldMax+oldVal
        thisData.datasets[thisData.datasets.length -1].data[0] = Rounder(newVal)
    }

    if(calcaultedPerformance<0){
        let oldVal = thisData.datasets[0].data[0]
        let newVal = calcaultedPerformance-oldVal
        thisData.datasets[0].data[0] = Rounder(newVal)
        suggestedMin = Rounder(newVal)
    }

    let thisOptions = {
        indexAxis: 'y',
        layout: {
            padding: {
                bottom:0,
                top:35
            }
        },
        elements: {
            bar: {
                borderWidth: 0,
            },
        },
        barPercentage: 0.6,
        categoryPercentage: 1,
        responsive: true,
        maintainAspectRatio:false,
        scales: {
            x: {
                stacked: true,
                min:suggestedMin,
                max:suggestedMax,
                ticks:{
                    display:true,
                }
                },
            y: {
                stacked: true,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip:{
                enabled: false
            },
            annotation: {
                clip: false,
                annotations: {
                    line1: {
                        type: 'line',
                        xMin: baselinePerformance,
                        xMax: baselinePerformance,
                        borderWidth: 3,
                        borderColor: 'rgba(0,0,0,0.6)',
                        borderDash:[5,3],
                        label:{
                            display:false,
                            content:'Baseline: '+baselinePerformance,
                            backgroundColor: 'rgba(0,0,0,0.6)',
                            borderDash:[5,3],
                            position:'end',
                            yAdjust:-20,
                            font:{
                                size:14
                            }
                        },
                        enter({element}, event) {
                            element.label.options.display = true;
                            return true;
                        },
                        leave({element}, event) {
                            element.label.options.display = false;
                            return true;
                        }
                    },
                    calcIndicator: {
                        type: 'polygon',
                        sides:3,
                        radius:5,
                        yAdjust:-10,
                        rotation:180,
                        xMin: calcaultedPerformance,
                        xMax: calcaultedPerformance,
                        borderWidth: 3,
                        borderColor: 'rgba(51,51,51,1)',
                        backgroundColor: 'rgba(51,51,51,1)',
                    },
                    indicatorLabel:{
                        type: 'label',
                        xValue: calcaultedPerformance,
                        yValue: 1,
                        yAdjust:-50,
                        borderRadius:5,
                        backgroundColor: Colourer(calcaultedPerformance,kpis.name,plotData),
                        content: [calcaultedPerformance],
                        font: {
                            size: 12
                        }
                    }

                }
            },
        },
    };
    return <Bar options={thisOptions} data={thisData} />;
}

/**
 * Generates all the KPI charts
 * @date 8/21/2023 - 12:25:35 PM
 *
 * @export
 * @returns {*} A div with kpi charts within
 */
export function AllKPICharts(){
    const {projectData} = useContext(AuthContext);
    const {thisPlot, KPIVals, baselinePlot} = useContext(DashContext);
    // console.log(KPIVals)
    if(!("name" in baselinePlot)){
        return ""
    }

    return <>
        {
            thisPlot?.kpi.map((data)=>(
                <div className="kpi-chart-wrap" key={data.name}>
                    <div className='kpi-chart-label'>
                        {renameFactors(data.name)} <br></br>({addUnits(data.name)})
                    </div>
                    <div className='kpi-chart'>
                        {KPIChart(data, thisPlot, KPIVals, projectData, baselinePlot)}
                    </div>
                    
                </div>
            ))
        }
    
    </>

}