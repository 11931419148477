import './Header.css';
import React, {useContext} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../utils/Context';
import { getCookie } from '../../utils/Cookies';

/**
 * Header used for every page except login
 * @date 8/21/2023 - 12:20:31 PM
 *
 * @returns {*}
 */
function Header() {
    const {setAuth} = useContext(AuthContext);
    const navigate = useNavigate();
    let adminCookie = getCookie("admin")
  

    const logout = () => {
        setAuth({
            "project":null,
            "auth":false
        })
        localStorage.removeItem("auth")
        localStorage.removeItem("project")
        document.cookie = "admin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate('/')
    }

    // console.log(adminCookie)

    return (
        <div className="header">
            <h1 className="title">Biome</h1>
            <nav className="navbar">
                <NavLink to="/project" className="nav-link">
                    Overview
                </NavLink>
                {
                    adminCookie==="true"?
                        <NavLink to="/configuration" className="nav-link">
                            Configuration
                        </NavLink>
                    :<></>
                }
                <NavLink to="/about" className="nav-link">
                    About
                </NavLink>
                <NavLink to="/glossary" className="nav-link">
                    Glossary
                </NavLink>

            </nav>
            
            <img src="/media/HL_logo_white.png" height="30" alt="logo" className="header-logo"></img>
            <div className="logout-button" onClick={logout}>
                Logout
            </div>
        </div>
    );
  }
  
  export default Header;
  