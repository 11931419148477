import {createContext} from 'react';
/**
 * Context for project level information
 * @date 8/21/2023 - 10:30:53 AM
 *
 * @type {*}
 */
export const AuthContext = createContext({});
/**
 * Context for entity/dashboard level information
 * @date 8/21/2023 - 10:30:53 AM
 *
 * @type {*}
 */
export const DashContext = createContext({});