import React, { useState, useContext, useEffect} from 'react';
import {AuthContext} from '../utils/Context';
import {useNavigate} from 'react-router-dom';

function Welcome() {

    const [thisPassword, setPassword] = useState();
    const {thisProject, setProject, thisAuth, setAuth} = useContext(AuthContext);
    const navigate = useNavigate();

    const localAuth = JSON.parse(localStorage.getItem("auth"))

    useEffect(() => {
        if(thisAuth.project!=null || localAuth!=null){
            navigate('/project')
        }
        if(localAuth!=null){
            setAuth(localAuth)
        }
    }, [thisAuth]);

    const handleSubmit = async(e) => {
        e.preventDefault();
        fetch('/authenticate', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({
                project:thisProject,
                password:thisPassword
            })
        })
        .then((response) => response.json())
        .then((res) => {
            if(res.loggedIn){
                setAuth(res)
                localStorage.setItem("auth",JSON.stringify(res))
                navigate('/project');
            } else if(!res.loggedIn){
                alert("Incorrect Password")
            } else {
                alert("Incorrect login details")
            }
        })
    }

    return (
        <div className="content-splash">

            <div className="login-wrapper">
                <p className='title'>Biome</p>
                <p className="desc">A Hoare Lea tool to improve decision making around Natural Capital on projects.
                Use the login below to access your project.</p>
                <form onSubmit={handleSubmit}>
                <label className='input-wrap'>
                    <p className="subtitle">Project</p>
                    <input type="text" onChange={e => setProject(e.target.value)}/>
                </label>
                <label className='input-wrap'>
                    <p className="subtitle">Password</p>
                    <input type="password" onChange={e => setPassword(e.target.value)}/>
                </label>
                <div className="button-wrap">
                    <button type="submit">Submit</button>
                </div>
                </form>
                <div className='bottom'>
                    <img src='/media/HL_logo_white.png' height="35" alt="logo" className="HL-logo"></img>
                </div>
            </div>
            
      </div>
    );
  }
  
  export default Welcome;
  