import React, { useEffect} from 'react';
import Header from '../components/Header/Header';

const glossaryTerms = [
    {
        "name":"Biodiversity Net Gain",
        "desc":"An approach to building development that aims to have a net positive impact on biodiversity.  To achieve net gain, biodiversity value attributable to a development must exceed the pre-development value by 10%.",
        "link":"https://ukgbc.org/about/glossary/",
        "source":"UKGBC"
    },
    {
        "name":"Biodiversity",
        "desc":"Short for biological diversity—means the diversity of life in all its forms—the diversity of species, of genetic variations within one species, and of ecosystems. ",
        "link":"https://www.cbd.int/cepa/toolkit/2008/doc/CBD-Toolkit-Glossaries.pdf",
        "source":"CBD Toolkit Glossary"
    },
    {
        "name":"Canopy cover ",
        "desc":"The area of the canopy (in plan view) as a proportion of total area of visible sky.",
        "link":"https://treeterms.co.uk/files/definitions/tree-pit/",
        "source":"Tree terms"
    },
    {
        "name":"Carbon Pricing",
        "desc":"A cost applied to carbon pollution to encourage polluters to reduce the amount of greenhouse gases they emit into the atmosphere. It means giving a monetary value to greenhouse gas.",
        "link":"https://ukgbc.org/about/glossary/",
        "source":"UKGBC"
    },
    {
        "name":"Climate Adaptation",
        "desc":"The process of adjustment to actual or expected climate and its effects. In human systems, adaptation seeks to moderate or avoid harm or exploit beneficial opportunities. In some natural systems, human intervention may facilitate adjustment to expected climate and its effects. ",
        "link":"https://www.ipcc.ch/site/assets/uploads/2019/01/SYRAR5-Glossary_en.pdf",
        "source":"IPCC"
    },
    {
        "name":"Climate change",
        "desc":"A change in the state of the climate that can be identified (e.g., by using statistical tests) by changes in the mean and/or the variability of its properties and that persists for an extended period, typically decades or longer. Climate change may be due to natural internal processes or external forcings such as modulations of the solar cycles, volcanic eruptions and persistent anthropogenic changes in the composition of the atmosphere or in land use. A change of climate which is attributed directly or indirectly to human activity that alters the composition of the global atmosphere and which is in addition to natural climate variability observed over comparable time periods",
        "link":"https://www.ipcc.ch/site/assets/uploads/2019/01/SYRAR5-Glossary_en.pdf",
        "source":"IPCC/UNFCCC"
    },
    {
        "name":"Climate Resilience",
        "desc":"The ability to prepare for, anticipate and respond to dangerous events or disturbances related to the effects of climate change. E.g., resilient buildings built to withstand floods.",
        "link":"https://ukgbc.org/about/glossary/",
        "source":"UKGBC"
    },
    {
        "name":"Ecosystem",
        "desc":"An ecosystem is a dynamic complex of vegetable, animal and microorganism communities and their non-living environment that interact as a functional unit. Ecosystems may be small and simple, like an isolated pond, or large and complex, like a specific tropical rainforest or a coral reef in tropical seas.",
        "link":"https://portals.iucn.org/library/sites/library/files/documents/2020-021-En.pdf",
        "source":"IUCN Glossary"
    },
    {
        "name":"Extensive green roof",
        "desc":"These generally require a 5-15cm (2-6in) depth of growing medium that can consist mainly of lightweight inorganic materials such as perlite, leca, sand, rockwool and crushed tiles or concrete. The typical layers of an extensive green roof include waterproofing and insulation layers, a root barrier membrane, a drainage layer, a filter layer and then the growing medium above.",
        "link":"https://www.rhs.org.uk/garden-features/green-roofs",
        "source":"Royal horticultural society"
    },
    {
        "name":"Green roof",
        "desc":"A roof with plants growing on its surface, varying from designs that are self-sustaining with just a thin layer of rooting medium to roof gardens which require a deeper layer, periodic irrigation, and adequate structural support (since they are heavy).",
        "link":"https://treeterms.co.uk/files/definitions/tree-pit/",
        "source":"Tree terms"
    },
    {
        "name":"Greenspace",
        "desc":"Any vegetated land or water within an urban area; this includes parks, gardens, playing fields, children’s play areas, woods and other natural areas, grassed areas, cemeteries and allotments green corridors like paths, disused railway lines, rivers and canals derelict, vacant and contaminated land which has the potential to be transformed.",
        "link":"https://www.greenspacescotland.org.uk/what-is-greenspace",
        "source":"Greenspace Scotland"
    },
    {
        "name":"Green wall",
        "desc":"A wall covered with plants that are growing in containers or on special material attached to the wall, often used when there is not much space to grow things on the ground.",
        "link":"https://dictionary.cambridge.org/dictionary/english/green-wall",
        "source":"Cambridge Dictionary"
    },
    {
        "name":"Habitat",
        "desc":"A place or type of site where an organism or population naturally occurs.",
        "link":"https://www.cbd.int/cepa/toolkit/2008/doc/CBD-Toolkit-Glossaries.pdf",
        "source":"CBD Glossary"
    },
    {
        "name":"Intensive green roof",
        "desc":"These are usually container or raised bed gardens. They need at least 30cm (1ft) depth of growing medium, much of which needs to be organic matter. Provides the best insulation, drainage management and temperature regulation – they can essentially replicate a natural landscape and can even be used for agricultural purposes.",
        "link":"https://www.rhs.org.uk/garden-features/green-roofs",
        "source":"Royal Horticultural Society"
    },
    {
        "name":"Landscape approach",
        "desc":"The landscape approach is based on an emerging set of principles that emphasize adaptive management, stakeholder involvement, and multiple objectives to address societal concerns about environment and development trade-offs.",
        "link":"https://www.pnas.org/content/110/21/8349",
        "source":"Sayer et al., 2013"
    },
    {
        "name":"Landscape (geographical)",
        "desc":"A landscape is part of Earth's surface that can be viewed at one time from one place. It consists of the geographic features that mark, or are characteristic of, a particular area.",
        "link":"https://education.nationalgeographic.org/resource/landscape/",
        "source":"National Geographic"
    },
    {
        "name":"Nature",
        "desc":"All the animals, plants, rocks, etc. in the world and all the features, forces, and processes that happen or exist independently of people, such as the weather, the sea, mountains, the production of young animals or plants, and growth",
        "link":"https://dictionary.cambridge.org/dictionary/english/nature",
        "source":"Cambridge Dictionary"
    },
    {
        "name":"Nature-based Solutions",
        "desc":"Solutions that are inspired and supported by nature, which are cost-effective, simultaneously provide environmental, social and economic benefits and help build resilience.",
        "link":"https://ukgbc.org/about/glossary/",
        "source":"UKGBC"
    },
    {
        "name":"Natural",
        "desc":"The natural environment comprises all living and non-living things that occur naturally on Earth. In its purest sense, it is thus an environment that is not the result of human activity or intervention. ",
        "link":"https://www.cbd.int/cepa/toolkit/2008/doc/CBD-Toolkit-Glossaries.pdf",
        "source":"CBD Toolkit Glossary"
    },
    {
        "name":"Species",
        "desc":"A group of interbreeding individuals with common characteristics that produce fertile (capable of reproducing) offspring and which are not able to interbreed with other such groups, that is, a population that is reproductively isolated from others; related species are grouped into genera. A letter or number designation after the generic name, for example, Squatina sp.",
        "link":"https://portals.iucn.org/library/sites/library/files/documents/2020-021-En.pdf",
        "source":"IUCN Glossary"
    },
    {
        "name":"Sustainable Drainage Systems",
        "desc":"A natural approach to managing drainage in and around properties and other developments. There are multiple types of SuDS. Source control measures deal with run-off at, or close to, the surface where rainfall lands. Site control measures manage the surface water run-off from larger areas, such as part of a housing estate, major roads or business parks. The run-off from larger areas can be channelled to a site control measure using swales (shallow drainage channels) or filter drains. Regional control measures downstream of source and site controls deal with the gathered run-off from a large area. These systems use the same principles as smaller scale SuDS but can cope with larger volumes of water.",
        "link":"https://www.netregs.org.uk/environmental-topics/water/sustainable-drainage-systems-suds/what-are-sustainable-drainage-systems/",
        "source":"NetRegs"
    },
    {
        "name":"Tree pit",
        "desc":"The hole in the ground in which a tree is planted. In the urban context the pit may represent the whole of the root volume available to the tree when mature.",
        "link":"https://treeterms.co.uk/files/definitions/tree-pit/",
        "source":"Tree terms"
    },
    {
        "name":"Urban Greening Facotr (UGF)",
        "desc":"The UGF is a tool to evaluate the quality and quantity of urban greening. The UGF is composed of two key factors; (a) a target factor score that sets a minimum proportion or percentage of greening for a particular site, area or land use; and, (b) a schedule of surface cover types and associated factor weightings that are used to calculate the score. The UGF score is calculated by multiplying the area of each surface cover type by its factors; each figure is then added together and divided by the total area within the development site boundary. ",
        "link":"https://publications.naturalengland.org.uk/file/6373170402820096",
        "source":"Natural England"
    },


]

function Glossary() {

    useEffect(() => {
    }, []);
    
    return (
        <>
            <Header />
            <div className="background">
                <div className="wrapper">
                    <div className='glossary'>
                        <div className='title'>Glossary</div>
                        <div className='main-text'>
                            This glossary provides and overview of common terms found throughout Biome.
                        </div>
                            {glossaryTerms.map((data, index)=>(
                                <div key={index} className='glossary-term'>
                                    <p className='subtitle'>{data.name}</p>
                                    <p className='main-text'>{data.desc} </p>
                                    <p className='source'><a href={data.link}>({data.source})</a></p>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
  }
  
  export default Glossary;
  