import { useRef, useContext } from 'react';
import './SaveOption.css'
import { getCookie } from '../../utils/Cookies';
import { AuthContext, DashContext } from '../../utils/Context';
import { createDate, prepKPI } from '../../utils/Basic';

function SaveOption (){
    const {projectData, setProjectData} = useContext(AuthContext);
    const {setPlot, areaValues, inputValues, KPIVals,thisPlot,setNameWarning} = useContext(DashContext);

    const optionName = useRef(null);
    const defaultOption = useRef(false);
    let adminCookie = getCookie("admin")
    if(adminCookie==="false"){
        return <></>
    }

    let dataCookie = getCookie("name")

    const submitForm = (e) => {
        e.preventDefault();
        const newName = optionName.current.value
        const defaultSet = defaultOption.current.checked 
        var nameCheck = thisPlot.versions.some(function(el){
            return (el.name.toLowerCase()===newName.toLowerCase())
        })
        if(nameCheck){
            setNameWarning(true)
            return
        }
        const today = createDate()
        const plotName = thisPlot.name
        const newValues = inputValues
        const newAreavalues = areaValues
        const performance = KPIVals
        const configPerformance = prepKPI(performance)

        let entityIndex = projectData?.entities.findIndex((obj => obj.name === plotName));

        let newEntity = {
            lastUpdate:today,
            name:newName,        
            default:defaultSet,
            performance:configPerformance,
            area: newAreavalues,
            ugf:newValues,
            bng:{}
        }

        projectData?.entities[entityIndex].versions.push(newEntity)
        let updatedProjectData = {...projectData}
        let updatePlot = updatedProjectData.entities[entityIndex]

        if(defaultSet){
            let defaultIndex = updatedProjectData?.entities[entityIndex].versions.findIndex((obj => obj.default === true));
            updatedProjectData.entities[entityIndex].versions[defaultIndex].default = false
        }

        setProjectData(updatedProjectData)
        setPlot({...updatePlot})
        localStorage.setItem("project",JSON.stringify(updatedProjectData))

        fetch('/save-data/'+dataCookie, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedProjectData)
        })
        .then(response => {
            console.log(response);
            optionName.current.value = ""
        })
    }
    
    return (
        <div className='results-pill'>
            <div className="results-pill-title">
                Save New Option
            </div>
            <div className="save-wrapper">
                <form onSubmit={submitForm}>
                    <input type="text" className="option-name-input" placeholder="Option Name (max 15 chars)" maxLength="15" id="option-name" ref={optionName}></input>
                    
                    <label className="container">
                    <input type="checkbox" className="option-checkbox" id="set-default-option" ref={defaultOption}></input>
                        <p className='checkbox-label'>Set Default</p>
                        <span className="checkmark"></span>
                    </label>
                    
                    <button>Save Option</button>
                </form>
            </div>
        </div>
    )
}

export default SaveOption