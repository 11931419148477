import './ConfigInput.css'

/**
 * Input field and label for Config page
 * @date 8/21/2023 - 10:36:21 AM
 * 
 * @typedef {object} Props
 * @property  {string} title Label of the input
 * @property  {string} code Reference code from the JSON
 * @property  {number} value Default valute for the input field
 * @property  {number} width Width of the input field
 * 
 * @param {Props} props
 * @returns {*}
 */
export const ConfigInput = (props) => {
    // const {projectData} = useContext(AuthContext);
    // let val
    // if(props.type==="project"){
    //     val = props.code.split('.').reduce((a,v) => a[v], projectData)
    // } else {
    //     val = props.code
    // }
    // const [defValue, setDefValue] = useState(props.value)

    return (
        <div className='individual-input-wrap'>
            <div className='input-title'>{props.title}</div>
            <input type="text" className="input-field" style={{width:props.width}} defaultValue={props.value} id={props.code} ></input>
        </div>
       
    )
}