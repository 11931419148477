/**
 * Checks what status the KPI is performing at, and returns the relevant string to describe
 * @date 8/21/2023 - 10:32:15 AM
 *
 * @param {Number} value
 * @param {String} kpi
 * @param {{}} data
 * @returns {string}
 */

function TargetChecker (value, kpi, data) {
    let thisKPI = data.kpi?.find(o => o.name === kpi)
    let performance = "Failing Planning Policy"

    for(let target in thisKPI.targets){
        if(thisKPI.targets[target].value<=value){
            performance = thisKPI.targets[target].name
        }
    }
    return performance
}

export default TargetChecker