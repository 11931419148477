import './PlotCard.css'
import {AuthContext} from '../../utils/Context'; 
import React, { useContext } from 'react';
import Colourer from '../../utils/Colourer';
import { Link } from 'react-router-dom';

/**
 * Creates the old design for individual plot cards
 * @date 8/21/2023 - 2:51:53 PM
 *
 * @typedef {object} Props
 * @property  {{}} data Entity data object
 * 
 * @param {*} props
 * @returns {*} Div plot card
 */
function PlotCard(props) {
    const {thisAuth} = useContext(AuthContext);
    let defaultVersion = props.data.versions.find(o=> o.default === true)

    return (
        <div className="plot-card">
             <Link to={`/dashboard/${thisAuth.project}/${props.data.name}`}>
                <div className="plot-name">
                    <p className="title">{props.data.name}</p>
                    <div className='arrow'></div>
                </div>
            </Link>
            <div className="trackers">
                {   
                    defaultVersion?.performance?.map((data)=>(
                        <div className="tracker-row" key={data.name}>
                            
                            <p className="name">{data.name}</p>
                            <div className="indicator" style={{
                                    backgroundColor:Colourer(data.value, data.name, props.data)
                                }}>
                                {data.value}
                            </div>
                            
                        </div>
                    ))
                } 
            </div>

        </div>
    );
  }
  
  export default PlotCard;
  