import './Warning.css'
import { useContext } from 'react'
import { DashContext,AuthContext } from '../../utils/Context'
import { getCookie } from '../../utils/Cookies'
import { createDate, prepKPI } from '../../utils/Basic';

const TitleMessage = () => {
    const data = [
        "Woah",
        "Careful now",
        "Are you sure?",
    ]
    let length = data.length
    let rand = Math.random() * length
    let pos =  Math.floor(rand)
    return data[pos]
}

const MistakeMessage = () => {
    const data = [
        "Whoops",
        "Oh oh"
    ]
    let length = data.length
    let rand = Math.random() * length
    let pos =  Math.floor(rand)
    return data[pos]
}

export const Warning = () => {
    const {
        setWarning,
        warningContent,
        thisPlot, setPlot,
        inputValues, KPIVals
    } = useContext(DashContext)
    
    const {
        projectData, setProjectData, 
    } = useContext(AuthContext);

    
    const plotName = thisPlot.name
    let entityIndex = projectData?.entities.findIndex((obj => obj.name === plotName))
    const dataCookie = getCookie("name")
    const method = projectData.config.method

    const Confirm = () => {
        if(warningContent.type === "Delete"){
            let newPlot = {...thisPlot}
            newPlot.versions.splice(warningContent.position,1)
            setPlot(newPlot)
            let newProjectData = {...projectData}
            newProjectData.entities[entityIndex] = newPlot
            setProjectData(newProjectData)
            localStorage.setItem("project",JSON.stringify(newProjectData))
            fetch('/save-data/'+dataCookie, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newProjectData)
            })
            .then(response => {
                console.log(response);
            })

            setWarning(false)
        } else if (warningContent.type === "Overwrite"){
            const today = createDate()
            const newValues = {...inputValues}
            const performance = {...KPIVals}
            const configPerformance = prepKPI(performance)
            let newPlot = {...thisPlot}
            let newVersion = newPlot.versions[warningContent.position]
            
            newPlot.versions[warningContent.position].lastUpdate = today
            newPlot.versions[warningContent.position].performance = configPerformance
            newPlot.versions[warningContent.position][method] = newValues
            setPlot(newPlot)
            let entityIndex = projectData.entities.findIndex((obj => obj.name === newVersion.name))
            let newProjectData = {...projectData}
            newProjectData.entities[entityIndex] = newPlot
            setProjectData(newProjectData)
            localStorage.setItem("project",JSON.stringify(newProjectData))
            fetch('/save-data/'+dataCookie, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newProjectData)
            })
            .then(response => {
                console.log(response);
            })
            setWarning(false)
        }
    }

    const Cancel = () => {
        setWarning(false)
    }

    return (
        <div className='warning-wrapper'>
            <div className='popup'>
                <p className='main-title'><TitleMessage/></p>
                <p className='message'>{warningContent.message}</p>
                <p className='message'>This is an irreversable change and cannot be undone.</p>
                <div className='button-wrap'>
                    <button onClick={Cancel}>Cancel</button>
                    <button className='delete' onClick={Confirm}>{warningContent.type}</button>
                </div>
                
            </div>
        </div>
    )
}

export const SameName = () => {
    const {
        setNameWarning
    } = useContext(DashContext)
    
    const Confirm = () => {
        setNameWarning(false)
    }

    return (
        <div className='warning-wrapper'>
            <div className='popup'>
                <p className='main-title'><MistakeMessage/></p>
                <p className='message'>It seems that name already exists.<br></br><br></br>
                Please try another name, or overwrite an existing option using the table above</p>
                <div className='button-wrap'>
                    <button className='delete' onClick={Confirm}>Okay</button>
                </div>
            </div>
        </div>
    )
}