import { useContext } from 'react'

import './Legend.css'
import { AuthContext } from '../../utils/Context';

export const Legend = () => {
    
    const {
        projectData, 
    } = useContext(AuthContext);
    
    if(!projectData){
        return <></>
    }

    let levels = projectData.config.levels

    return (
        <div className='legend'>
            <div className='legend-item-wrap'>
                {Object.keys(levels).map((data,index)=>(
                    <div className='item' key={levels[data].name}>
                        <div className='square' key={"square_"+index} style={{backgroundColor: levels[data].color }}></div>
                        <div className='label' key={"label_"+index}>{levels[data].name}</div>
                    </div>
                ))}
            </div>
            
        </div>
       
    )
}