import { useContext,useState } from 'react';
import { DashContext, AuthContext } from '../../utils/Context';
import Colourer from '../../utils/Colourer';
import './OptionsTable.css'
import { getCookie } from '../../utils/Cookies';
import { addUnits, renameFactors } from '../../utils/Basic';

/**
 * Creates the selection of edit buttons for the each option row
 * @date 8/21/2023 - 12:50:28 PM
 * 
 * @typedef {object} Props
 * @property  {number} position Position of option within entity array
 * @property  {{}} data Specific option data
 *
 * @param {Props} props
 * @returns {*}
 */
function CreateButtons(props){
    const [optionsOpen, setOpen] = useState(false)
    const {
        thisPlot, setPlot,
        setBaseValues, 
        setInputValues, 
        setBaseAreaValues,
        setAreaValues,
        setWarning,
        setWarningContent
    } = useContext(DashContext);

    const {
        projectData, setProjectData, 
    } = useContext(AuthContext);

    const onEnter = () => {
        setOpen(true)
    }
    
    const mouseLeave = () => {
        setOpen(false)
    }

    const onClick = () => {
        setOpen(false)
    }

    const thisDefault = props.data.default
    const position = props.position
    const selectedInputs = thisPlot?.versions[position]?.ugf
    const areaInputs = thisPlot?.versions[position]?.area
    const dataCookie = getCookie("name")

    const updatedInputs = () => {
        setInputValues({...selectedInputs})
        setBaseValues({...selectedInputs})
        setAreaValues({...areaInputs})
        setBaseAreaValues({...areaInputs})   
    }

    const deleteOption = () => {
        setWarning(true)
        setWarningContent({
            type:'Delete',
            message:'This action will delete all saved information for only this option',
            position:position
        })
    }

    const newDefault = () => {
        let newPlot = {...thisPlot}
        for(let version in newPlot.versions){
            newPlot.versions[version].default = false
        }
        newPlot.versions[position].default = true
        setPlot(newPlot)
        let entityIndex = projectData.entities.findIndex((obj => obj.name === props.data.name))
        let newProjectData = {...projectData}
        newProjectData.entities[entityIndex] = newPlot
        setProjectData(newProjectData)
        localStorage.setItem("project",JSON.stringify(newProjectData))
        fetch('/save-data/'+dataCookie, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newProjectData)
        })
        .then(response => {
            // console.log(response);
        })
    }

    const overwriteData = () => {
        setWarning(true)
        let name = thisPlot.versions[position].name
        setWarningContent({
            type:'Overwrite',
            message:'This action will overwrite the "'+name+'" option with the currently configured inputs below',
            position:position
        })
    }

    let defaultButton
    if(thisDefault){
        defaultButton = <></>
    }else{
        defaultButton = <button onClick={newDefault}>Set Primary</button>
    }

    let adminCookie = getCookie("admin")
    let authButtons
    if(adminCookie==="true"){
        authButtons = <>
            {defaultButton}
            <button className='delete' onClick={overwriteData}>Overwrite</button>
            <button className='delete' onClick={deleteOption}>Delete</button>
        </>
    } else {
        authButtons = <></>
    }

    return(
        <div className={optionsOpen?"edit-wrap":"edit-wrap closed"} onMouseEnter={onEnter} onMouseLeave={mouseLeave}>
            <div className="cell edit-cell">Edit</div>
            <div className='button-column' onClick={onClick}>
                <button onClick={updatedInputs}>Load Values</button>
                {authButtons}
            </div>
        </div>
        
    )
}

/**
 * Creates a row adjacent to option name that has the values/colours for performance against KPIs
 * @date 8/21/2023 - 12:50:28 PM
 *
 * @param {{}} data The individual option data
 * @param {{}} plot Overall entity data
 * @returns {*} Creates the row of coloured cells and values within the table
 */
function plotRow(data, plot){
    return (
        <>
            <div className='results'>
                {data.performance.map((kpiData, index)=>(
                    <div 
                        className='cell' 
                        key={index} 
                    >
                        <div className='indicator'style={{backgroundColor:Colourer(kpiData.value, kpiData.name, plot)}}></div>
                        {kpiData.value}
                    </div>
                ))}
                
            </div>
        </>
    )
}


/**
 * Creates main options table within dashboard to interact with design options for an entity
 * @date 8/21/2023 - 12:50:28 PM
 *
 * @returns {*}
 */
function OptionsTable() {
    const {thisPlot
        } = useContext(DashContext);
    if(!thisPlot){
        return ""
    }

    return (
        <div className='options-table-wrapper'>
            <div className='header-row'>
                <div className='header-cell cell first-column'>
                    Name 
                </div>
                {
                    thisPlot?.versions[0]?.performance?.map((data, index)=>(
                        <div className='header-cell cell' key={index}>{renameFactors(data.name)} <br></br> ({addUnits(data.name)}) </div>
                    ))
                }
            </div>
            {
                thisPlot.versions?.map((data, index)=>(
                    <div key={index+data.name}>
                        <div className='content-row' key={index}>
                            <div className="cell content-cell first-column" key={data.name}>{data.name} <span className='default'> {data.default?"★":""}</span></div>
                            {plotRow(data, thisPlot)}
                        </div>
                        <CreateButtons data={data} position={index} key={data.name + index}/>
                    </div>
                    
                ))
            }
        </div>
    )
}

export default OptionsTable