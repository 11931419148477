/**
 * Retrieves a cookie value
 * @date 8/21/2023 - 10:31:33 AM
 *
 * @export
 * @param {String} key Cookie key to look up
 * @returns {*} Retrieved Cookie value
 */
export function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }