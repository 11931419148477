import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createBrowserHistory } from "history";

// import ReactGA from 'react-ga4';
// ReactGA.initialize("G-EKD29QBEGQ");

// const history = createBrowserHistory();
// history.listen(location => {
//   ReactGA.set({ page: location.pathname });
//   ReactGA.send(location.pathname);
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // {/* </React.StrictMode> */}
);