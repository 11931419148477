import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './css/main-style.css';
import Projects from './routes/Projects';
import Configuration from './routes/Configuration';
import Welcome from './routes/Welcome';
import About from './routes/About';
import React, { useState } from 'react';
import {AuthContext} from './utils/Context';
import Dashboard from './routes/Dashboard';
import Glossary from './routes/Glossary';

// import GA4React, { useGA4React } from "ga-4-react";
/**
 * Main React Application
 * @date 8/21/2023 - 10:34:10 AM
 *
 * @returns {*}
 */
// const ga4react = new GA4React("G-EKD29QBEGQ");
function App() {
  
  const [thisProject, setProject] = useState();
  const [thisAuth, setAuth] = useState({
    "project":null,
    "auth":false,
    "data":null
  });
  const [projectData, setProjectData] = useState(false)

  
  // useGA4React();

  return (
    
      <div className="App">
        
      <AuthContext.Provider value={{
        thisProject, setProject, 
        thisAuth, setAuth, 
        projectData, setProjectData, 
        }}>
        <Router>
            <Routes>
              <Route path="/" element={<Welcome />} />
              <Route path="/project" element={<Projects />} />
              <Route path="/dashboard/:project/:id" element={<Dashboard/>} />
              <Route path="/configuration" element={<Configuration/>} />
              <Route path="/about" element={<About />} />
              <Route path="/glossary" element={<Glossary />} />
              <Route path="*" element={<Welcome />} />
            </Routes>
          </Router>
      </AuthContext.Provider>
        
    </div>
  );
}

export default App;
