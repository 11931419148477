import './PlotTable.css'
import {AuthContext} from '../../utils/Context'; 
import React, { useContext } from 'react';
import Colourer from '../../utils/Colourer';
import { Link } from 'react-router-dom';

function PlotTable(props) {
    const {thisAuth} = useContext(AuthContext);
    let defaultVersion = props?.data?.versions.find(o=> o.default === true)

    return (
        <div className='content-row' key={props.data.name}>
            <Link to={`/dashboard/${thisAuth.project}/${props.data.name}`}>
                <div className='cell first-column'>
                    {props.data.name}
                    <div className='arrow'></div>
                </div>
            </Link>
            <div className='cell update'>
                {defaultVersion?.lastUpdate}
            </div>
            {
                defaultVersion?.performance.map((data)=>(
                    <div className='cell' key={props.data.name+data.name}>
                        <div className="indicator" key={"indicator"+props.data.name+data.name}style={{
                                backgroundColor:Colourer(data.value, data.name, props.data)
                            }}>
                        </div>
                        {data.value}
                    </div>
                ))
            }
            
        </div>
    );
  }
  
  export default PlotTable;
  