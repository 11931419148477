// const colours = {
//     "0":"#EEDE8B",
//     "1":"#D2DF9C",
//     "2":"#9BC274",
//     "3":"#9BC274"
// }

/**
 * Default colours -- to be replaced with project specific
 * @date 8/21/2023 - 10:29:14 AM
 *
 * @type {{ "0": string; "1": string; "2": string; "3": string; }}
 */
const colours = {
    "0":"rgba(239,185,75,0.7)",
    "1":"rgba(196,216,137,0.7)",
    "2":"rgba(150,190,45,0.7)",
    "3":"rgba(150,190,45,0.7)"
}

/**
 * Returns a colour to be used when styling an element based on KPI targets RAG colours
 * @date 8/21/2023 - 10:29:14 AM
 *
 * @param {Number} value Performance Value
 * @param {String} kpi KPI Name
 * @param {{}} data Entity information to derive targets to compare
 * @returns {String} A rgba(x,x,x,x) colour to be used
 */
function Colourer (value, kpi, data) {
    let thisKPI = data.kpi?.find(o => o.name === kpi)
    let threshold = "no"
    for(let target in thisKPI.targets){
        if(thisKPI.targets[target].value<=value){
            threshold = thisKPI.targets[target].level
        }
    }

    if(threshold==="no"){
        return "rgba(222, 93, 93, 1)"
    }
    return colours[threshold]
}

export default Colourer