import { useState } from 'react'

import './InputToggle.css'

/**
 * Toggle button
 * @date 8/21/2023 - 12:23:31 PM
 *
 * @param {{ label: string; toggled: boolean; onClick: Function; }} { name for the toggle, initial state and function to call when clicked }
 * @returns {*}
 */
export const InputToggle = ({ label, toggled, onClick }) => {
    const [isToggled, toggle] = useState(toggled)

    // const callback = () => {
    //     toggle(!isToggled)
    // }

    return (
        <div className='toggle-wrap'>
             <label className='toggle-back'>
                <p className='toggle-label'>{label}</p>
                <input type="checkbox" defaultChecked={isToggled} onClick={onClick} className='toggle'/>
                <span />
                
            </label>
            
        </div>
       
    )
}