import { AuthContext, DashContext } from "../../utils/Context";
import React, { useContext, useEffect, useState } from "react";
import AreaInputs from "../AreaInputs/AreaInputs";
import Slider from "../Slider/Slider";
import {
  TreeCanopy,
  TreesPlanted,
  AccessiblePlanting,
  UrbanGreening,
  BiodiversityNetGain,
  calcRainwaterManagement,
} from "../../utils/Calculations";
import {
  UpdateDeveloperValueMetrics,
  UpdateSocialValueMetrics,
  prepLandscapeGraph,
} from "../OtherCharts/OtherCharts";

let limitFunction = null;

function Sidebar() {
  const { projectData } = useContext(AuthContext);

  const {
    thisPlot,
    baseValues,
    baseAreaValues, 
    setKPIVals,
    inputValues, 
    setInputValues,
    areaValues,
    setAreaValues,
    treeArray,
    setTreeArray,
    setTreemapData,
  } = useContext(DashContext);

  const [areaOpened, setAreaOpened] = useState(false);
  const toggleArea = () => {
    areaOpened ? setAreaOpened(false) : setAreaOpened(true);
  };
  const [treesOpened, setTreesOpened] = useState(false);
  const toggleTrees = () => {
    treesOpened ? setTreesOpened(false) : setTreesOpened(true);
  };
  const [extGROpen, setextGROpen] = useState(false);
  const toggleExtGR = () => {
    extGROpen ? setextGROpen(false) : setextGROpen(true);
  };
  const [intGROpen, setintGROpen] = useState(false);
  const toggleIntGR = () => {
    intGROpen ? setintGROpen(false) : setintGROpen(true);
  };
  const [greenSpaceOpen, setgreenSpaceOpen] = useState(false);
  const toggleGreenSpace = () => {
    greenSpaceOpen ? setgreenSpaceOpen(false) : setgreenSpaceOpen(true);
  };
  const [greenWallOpen, setgreenWallOpen] = useState(false);
  const toggleGreenWall = () => {
    greenWallOpen ? setgreenWallOpen(false) : setgreenWallOpen(true);
  };
  const [sudsOpen, setSudsOpen] = useState(false);
  const toggleSuds = () => {
    sudsOpen ? setSudsOpen(false) : setSudsOpen(true);
  };

  const handleRelease = () => {
    if ("treesOverTwoThirds" in inputValues) {
      setTreemapData(
        prepLandscapeGraph(inputValues, areaValues, treeArray[2], treeArray[3], thisPlot)
      );
    }
  };

  const RunCalcs = (key1, key2, value) => {
    if (limitFunction) {
      clearTimeout(limitFunction);
    }

    limitFunction = setTimeout(() => {
      const newValues = JSON.parse(JSON.stringify(inputValues));
      newValues[key1][key2] = value;
      setInputValues(newValues);

      const thisTreeArray = TreeCanopy(newValues, areaValues, projectData, thisPlot);
      setTreeArray(thisTreeArray);
      const updatedPlot = JSON.parse(JSON.stringify(thisPlot));
      setKPIVals({
        "Urban Greening": UrbanGreening(
          newValues,
          areaValues, 
          thisTreeArray[2],
          thisTreeArray[3]
        ),
        "Biodiversity Change": BiodiversityNetGain(
          newValues,
          thisTreeArray[2],
          thisTreeArray[3],
          updatedPlot,
          true
        ),
        "Tree Cover": thisTreeArray[1],
        "Trees Planted": TreesPlanted(newValues),
        Greenspace: AccessiblePlanting(
          newValues,
          areaValues,
          thisTreeArray[0]
        ),
        Rainwater: calcRainwaterManagement(
          newValues,
          areaValues,
          thisTreeArray[2],
          thisTreeArray[3],
          updatedPlot
        ),
      });
      UpdateSocialValueMetrics(
        inputValues,
        treeArray[2],
        treeArray[3],
        40,
        2023,
        thisPlot,
        projectData,
        baseValues,
        baseAreaValues
      );
      UpdateDeveloperValueMetrics(
        inputValues,
        treeArray[2],
        treeArray[3],
        60,
        2023,
        thisPlot,
        projectData,
        1.03,
        baseValues,
        baseAreaValues
      );
    }, 20);
  };

  const GetAllInputs = () => {
    let newValues = JSON.parse(JSON.stringify(baseValues));
    setInputValues(newValues);
    let newAreaValues = JSON.parse(JSON.stringify(baseAreaValues));
    setAreaValues(newAreaValues);
    const thisTreeArray = TreeCanopy(newValues, newAreaValues, projectData, thisPlot);
    setTreeArray(thisTreeArray);
    const updatedPlot = JSON.parse(JSON.stringify(thisPlot));
    setKPIVals({
      "Urban Greening": UrbanGreening(
        newValues,
        newAreaValues,
        thisTreeArray[2],
        thisTreeArray[3]
      ),
      "Biodiversity Change": BiodiversityNetGain(
        newValues,
        thisTreeArray[2],
        thisTreeArray[3],
        updatedPlot,
        true
      ),
      "Tree Cover": thisTreeArray[1],
      "Trees Planted": TreesPlanted(newValues),
      Greenspace: AccessiblePlanting(newValues, newAreaValues, thisTreeArray[0]),
      Rainwater: calcRainwaterManagement(
        newValues,
        newAreaValues,
        thisTreeArray[2],
        thisTreeArray[3],
        updatedPlot
      ),
    });
    UpdateSocialValueMetrics(
      inputValues,
      treeArray[2],
      treeArray[3],
      40,
      2023,
      thisPlot,
      projectData,
      baseValues,
      baseAreaValues
    );
    UpdateDeveloperValueMetrics(
      inputValues,
      treeArray[2],
      treeArray[3],
      60,
      2023,
      thisPlot,
      projectData,
      1.03,
      baseValues,
      baseAreaValues
    );
  };

  useEffect(() => {
    GetAllInputs(); //eslint-disable-next-line
  }, [baseValues, baseAreaValues]);

  if (!("treesOverTwoThirds" in baseValues)) {
    return <></>;
  }

  let treesMax = areaValues.totalArea / 60;
  let areasMax = areaValues.totalArea / 4;
  let treesStep;
  let areasStep;

  if (treesMax < 50) {
    treesStep = 1;
  } else if (treesMax < 100) {
    treesStep = 2;
  } else if (treesMax < 250) {
    treesStep = 5;
  } else {
    treesStep = 10;
  }

  if (areasMax < 300) {
    areasStep = 1;
  } else if (areasMax < 500) {
    areasStep = 2;
  } else if (areasMax < 1000) {
    areasStep = 5;
  } else {
    areasStep = 10;
  }

  return (
    <div className="sidebar">
      <div className={areaOpened ? "subsection" : "subsection closed"}>
        <div className="title-row" onClick={toggleArea}>
          <div className="sidebar-title">Plot Area</div>
          <div className="arrow"></div>
        </div>

        <div className="sidebar-subtitle">Areas (m2)</div>
        <div className="sidebar-description">Speak to your Hoare Lea advisor to edit these values.</div>
        <div className="slider-section">
          <AreaInputs />
        </div>
      </div>

      <div className={treesOpened ? "subsection" : "subsection closed"}>
        <div className="title-row" onClick={toggleTrees}>
          <div className="sidebar-title">Trees</div>
          <div className="arrow"></div>
        </div>

        <div className="sidebar-subtitle">Small Pits</div>
        <div className="sidebar-description">
          Trees planted in pits with soil volumes less than two thirds of the
          projected canopy area of the mature tree
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.treesUnderTwoThirds?.smallTrees}
            min={0}
            max={treesMax}
            step={treesStep}
            id={["treesUnderTwoThirds", "smallTrees"]}
            title={"Small Trees (nr)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
          <Slider
            default={baseValues?.treesUnderTwoThirds?.mediumTrees}
            min={0}
            max={treesMax}
            step={treesStep}
            id={["treesUnderTwoThirds", "mediumTrees"]}
            title={"Medium Trees (nr)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
          <Slider
            default={baseValues?.treesUnderTwoThirds?.largeTrees}
            min={0}
            max={treesMax}
            step={treesStep}
            id={["treesUnderTwoThirds", "largeTrees"]}
            title={"Large Trees (nr)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>

        <div className="sidebar-subtitle">Large Pits</div>
        <div className="sidebar-description">
          Trees planted in connected tree pits with a minimum soil volume
          equivalent to at least two thirds of the projected canopy area of the
          mature tree
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.treesOverTwoThirds?.smallTrees}
            min={0}
            max={treesMax}
            step={treesStep}
            id={["treesOverTwoThirds", "smallTrees"]}
            title={"Small Trees (nr)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
          <Slider
            default={baseValues?.treesOverTwoThirds?.mediumTrees}
            min={0}
            max={treesMax}
            step={treesStep}
            id={["treesOverTwoThirds", "mediumTrees"]}
            title={"Medium Trees (nr)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
          <Slider
            default={baseValues?.treesOverTwoThirds?.largeTrees}
            min={0}
            max={treesMax}
            step={treesStep}
            id={["treesOverTwoThirds", "largeTrees"]}
            title={"Large Trees (nr)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>
      </div>

      <div className={extGROpen ? "subsection" : "subsection closed"}>
        <div className="title-row" onClick={toggleExtGR}>
          <div className="sidebar-title">Extensive Green Roof</div>
          <div className="arrow"></div>
        </div>

        <div className="sidebar-subtitle">Meets GRO 2014</div>
        <div className="sidebar-description">
          Extensive green roof with substrate of minimum settled depth of 80mm
          (or 60mm beneath vegetation blanket)
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.extensiveGreenRoof?.accessibleMeetsGRO}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["extensiveGreenRoof", "accessibleMeetsGRO"]}
            title={"Accessible Green Roof (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
          <Slider
            default={baseValues?.extensiveGreenRoof?.nonaccessibleMeetsGRO}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["extensiveGreenRoof", "nonaccessibleMeetsGRO"]}
            title={"Nonaccessible Green Roof (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>

        <div className="sidebar-subtitle">Doesn't meet GRO 2014</div>
        <div className="sidebar-description">
          Extensive green roof of sedum mat or other lightweight systems
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.extensiveGreenRoof?.accessibleDoesntMeetGRO}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["extensiveGreenRoof", "accessibleDoesntMeetGRO"]}
            title={"Accessible Green Roof (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
          <Slider
            default={baseValues?.extensiveGreenRoof?.nonaccessibleDoesntMeetGRO}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["extensiveGreenRoof", "nonaccessibleDoesntMeetGRO"]}
            title={"Nonaccessible Green Roof (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>
      </div>

      <div className={intGROpen ? "subsection" : "subsection closed"}>
        <div className="title-row" onClick={toggleIntGR}>
          <div className="sidebar-title">Intensive Green Roof</div>
          <div className="arrow"></div>
        </div>

        <div className="sidebar-subtitle">Non-accessible Green Roof</div>
        <div className="sidebar-description">
          Non-accessible intensive green roof or vegetation over structure.
          Vegetated sections only. Substrate minimum settled depth of 150mm
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.intensiveGreeRoof?.nonaccessGreenRoof}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["intensiveGreeRoof", "nonaccessGreenRoof"]}
            title={"Green Roof Area (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>

        <div className="sidebar-subtitle">Accessible Green Roof</div>
        <div className="sidebar-description">
          Accessible intensive green roof or vegetation over structure.
          Vegetated sections only. Substrate minimum settled depth of 150mm
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.intensiveGreeRoof?.accessibleGreenRoof}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["intensiveGreeRoof", "accessibleGreenRoof"]}
            title={"Green Roof Area (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>
      </div>

      <div className={greenSpaceOpen ? "subsection" : "subsection closed"}>
        <div className="title-row" onClick={toggleGreenSpace}>
          <div className="sidebar-title">Open Green Space</div>
          <div className="arrow"></div>
        </div>

        <div className="sidebar-subtitle">Semi-natural Vegetation</div>
        <div className="sidebar-description">
          Semi-natural vegetation (e.g. trees, woodland, species-rich grassland)
          maintained or established on site.
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.openGreenSpace?.semiNaturalVegetation}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["openGreenSpace", "semiNaturalVegetation"]}
            title={"Total Area (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>

        <div className="sidebar-subtitle">Flower-rich perennial planting</div>
        <div className="slider-section">
          <Slider
            default={baseValues?.openGreenSpace?.flowerRichPlanting}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["openGreenSpace", "flowerRichPlanting"]}
            title={"Total Area (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>

        <div className="sidebar-subtitle">
          Hedges (line of mature shrubs one or two shrubs wide).
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.openGreenSpace?.hedges}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["openGreenSpace", "hedges"]}
            title={"Total Area (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>

        <div className="sidebar-subtitle">Groundcover planting</div>
        <div className="slider-section">
          <Slider
            default={baseValues?.openGreenSpace?.groundCover}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["openGreenSpace", "groundCover"]}
            title={"Total Area (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>

        <div className="sidebar-subtitle">
          Amenity grassland (species-poor, regularly mown lawn).
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.openGreenSpace?.amenityGrassland}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["openGreenSpace", "amenityGrassland"]}
            title={"Total Area (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>
      </div>

      <div className={greenWallOpen ? "subsection" : "subsection closed"}>
        <div className="title-row" onClick={toggleGreenWall}>
          <div className="sidebar-title">Green Wall</div>
          <div className="arrow"></div>
        </div>

        <div className="sidebar-subtitle">Green Walls</div>
        <div className="sidebar-description">
          Green wall - modular system or climbers rooted in soil.
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.greenwall?.greenwall}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["greenwall", "greenwall"]}
            title={"Green Wall Area (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>
      </div>

      <div className={sudsOpen ? "subsection" : "subsection closed"}>
        <div className="title-row" onClick={toggleSuds}>
          <div className="sidebar-title">SuDS</div>
          <div className="arrow"></div>
        </div>
        <div className="sidebar-description">
          Sustainable Urban Drainage Systems (SuDS)
        </div>

        <div className="sidebar-subtitle">Wetland & Open Water</div>
        <div className="sidebar-description">
          Wetland or open water (semi-natural; not chlorinated) maintained or
          established on site
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.SuDS?.wetlandOpenWater}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["SuDS", "wetlandOpenWater"]}
            title={"Wetland & Open Water (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>

        <div className="sidebar-subtitle">Rain Gardens</div>
        <div className="sidebar-description">
          Rain gardens and other vegetated sustainable drainage elements
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.SuDS?.rainGardens}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["SuDS", "rainGardens"]}
            title={"Rain Gardens (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>

        <div className="sidebar-subtitle">Rain Features</div>
        <div className="sidebar-description">
          Water features (chlorinated) or unplanted detention basins
        </div>
        <div className="slider-section">
          <Slider
            default={baseValues?.SuDS?.waterFeature}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["SuDS", "waterFeature"]}
            title={"Water Features (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>

        {/* <div className="sidebar-subtitle">Permeable Paving</div> */}
        <div className="slider-section">
          <Slider
            default={baseValues?.SuDS?.permeablePaving}
            min={0}
            max={areasMax}
            step={areasStep}
            id={["SuDS", "permeablePaving"]}
            title={"Permeable Paving (sqm)"}
            func={RunCalcs}
            endFunc={handleRelease}
          />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
